import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NotificationActions from '../../store/ducks/notifier';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    const { notifyError, history } = this.props;

    notifyError('Erro interno');
    history.replace(history.location.pathname);
    if (process.env.NODE_ENV !== 'production') {
      console.log(error);
    }
    return {};
  }

  // componentDidCatch(error, info) {
  //   const { notifyError, history } = this.props;

  //   notifyError('Erro interno');
  //   history.replace(history.location.pathname);
  //   if (process.env.NODE_ENV !== 'production') {
  //     // console.log({
  //       error,
  //       info,
  //     });
  //   }
  // }

  render() {
    const { children } = this.props;

    return children;
  }
}

const mapDispatchToProps = dispatch => ({
  notifyError: (
    message,
    options,
  ) => dispatch(NotificationActions.notifyError(message, options)),
});

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));
