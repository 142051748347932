import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#262626',
    },
    secondary: {
      main: '#01ACF1',
    },
    progress:{
      main: '#1976d2',
    },
    button: {
      default: {
        dark: '#777',
        main: '#999',
        light: '#BBB',
      },
      success: {
        dark: '#549c64',
        main: '#78e08f',
        light: '#93e6a5',
      },
      error: {
        dark: '#a42004',
        main: '#eb2f06',
        light: '#ef5837',
      },
    },
    error: {
      main: '#e57878',
    },
    success: {
      main: '#30b72d',
    },
    background: {
      default: '#e1e1e1',
      white:  '#ffffff',
    },
  },
  
});

export default theme;
