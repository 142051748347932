/*eslint-disable-next-line*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import VideoAula from "../../components/VideoAula";
// import YouTubeIcon from '@material-ui/icons/YouTube';
import Button from '@material-ui/core/Button';
import gergo from '../../assets/images/logoEmpresas/logo-gergo-branco.png';
import gergoApp from '../../assets/images/logoEmpresas/logo-gergo-branco.png';
import './aulas.css'




const drawerWidth = 350;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));
function ResponsiveDrawer() {

  // const [finishedVideo, setFinishedVideo] = useState(false);
  const [titleVideo, setTitleVideo] = useState('Aula 1 - Alongamento');
  const [srcVideo, setSrcVideo] = useState(process.env.REACT_APP_URL_SISTEMA + '/aulas/001.mp4');
  const [posterVideo, setPosterVideo] = useState('');
  const dummyCategories = ['Aula 1 - Alongamento', 'Aula 2 - Fortalecimento Muscular', 'Aula 3 - Propriocepção (Equilíbrio)', 'Aula 4 - Coordenação Motora', 'Aula 5 - Relaxamento', 'Aula 6 - Exercícios Dinâmicos', 'Aula 7 - Fortalecimento Muscular 2', 'Aula 8 - Exercícios Articulares', 'Aula 9 - Coordenação Motora 2', 'Aula 10 - Alongamento 2']
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  // com Promises
  // useEffect(() => {
  //   api
  //     .get("/items")
  //     .then((response) => {
  //       setItems(response.data);
  //     })
  //     .catch((error) => {
  //       alert("Ocorreu um erro ao buscar os items");
  //     });
  // }, []);

  const handleListItemClick = (event, index) => {
    selectVideo(index)
    setSelectedIndex(index);
  };
  const selectVideo = (index) => {
    switch(index) {
        case 0:
          setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/001.mp4');
          setPosterVideo('');
          setTitleVideo('Aula 1 - Alongamento');
          break
        case 1:
          setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/002.mp4');
          setPosterVideo('');
          setTitleVideo('Aula 2 - Fortalecimento Muscular');
          break
        case 2:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/003.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 3 - Propriocepção (Equilíbrio)');
            break
        case 3:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/004.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 4 - Coordenação Motora');
            break
        case 4:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA + '/aulas/005.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 5 - Relaxamento');
            break
        case 5:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/006.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 6 - Exercícios Dinâmicos');
            break
        case 6:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/007.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 7 - Fortalecimento Muscular 2');
            break
        case 7:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/008.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 8 - Exercícios Articulares');
            break
        case 8:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/009.mp4');
            setPosterVideo('');
            setTitleVideo('Aula 9 - Coordenação Motora 2');
            break
        default:
            setSrcVideo(process.env.REACT_APP_URL_SISTEMA +'/aulas/010.mp4'); 
            setPosterVideo('');
            setTitleVideo('Aula 10 - Alongamento 2');
    }
  }

  const onVideoEnd = () => {
    console.log('terminou');
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  function Videos(props) {
    return (
      <VideoAula srcVideo={props.srcVideo} posterVideo={props.posterVideo} onVideoEnd={(value) => props.onVideoEnd()}/>
    );
  }

  function Buttons(props) {
    return (
      <div className="buttons">
        <Button variant="contained" className='button-previous' disabled={props.selectedIndex===0} onClick={(event) => props.handleListItemClick(event, props.selectedIndex-1)} color="primary">
          Anterior
        </Button>
        <Button variant="contained"  className='button-next' disabled={props.selectedIndex===9} onClick={(event) => props.handleListItemClick(event, props.selectedIndex+1)} color="primary">
          Proxima
        </Button>
      </div>
    );
  }

  const drawer = (
    <div>
      <List>
        {dummyCategories.map((text, index) => (
          <ListItem 
          selected={selectedIndex === index}
          button 
          key={text}
          onClick={(event) => handleListItemClick(event, index)}
          >
            <div className='menu-item'>
                <VideoLibraryIcon/>
                <div className='list-item'>
                    <ListItemText primary={text} />
                </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className='header'>
          <IconButton 
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography className="img-logo" variant="h6" noWrap>
              {/* eslint-disable-next-line*/}
              <img src={gergo} className='logo-gergo'/>
              {/* eslint-disable-next-line*/}
              <img src={gergoApp} className='logo-gergo-app'/>
          </Typography>
        </Toolbar>
      </AppBar>
      
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon/>
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>  
        </Hidden>
      </nav>
        <div className='toolbar-video'>
          <div className={classes.content}>
              <div className={classes.toolbar} />
              <div className='container-videos'>
                  <div className="disposition-video"> 

                    <div className='title-video'>
                      <div className="icon-video">
                          <VideoLibraryIcon className="youtube-icon"/>
                      </div>
                      {titleVideo}
                    </div>
                    <Videos srcVideo={srcVideo} posterVideo={posterVideo} onVideoEnd={onVideoEnd} />
                    <Buttons selectedIndex={selectedIndex} handleListItemClick={handleListItemClick}/>
                  </div>
            </div>
          </div>
      </div>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
export default ResponsiveDrawer;