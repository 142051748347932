/* eslint-disable quote-props */
export const lb_visaoGeral = {
  'box.postoComRisco': 'Postos com Riscos',  
  'box.postoSemRisco': 'Postos sem Riscos',
  'box.conforme': 'Conforme',
  'box.naoConforme': 'Não Conforme',
  'box.plano': 'Plano de ação',
  'box.acao': 'Ações Comitê',
  'box.absenteismos': 'Absenteísmos',
  'box.afastamentos': 'Afastamentos',
  'box.diasPerdidos': 'Dias perdidos',
  'box.blitz': 'Desvios Ergonômicos',
  'box.blitzCorretivas': 'Corretivas',
  'box.blitzPreventivas': 'Preventivas',
  'box.ginasticaLaboral': 'Adesão Ginástica Laboral',
  'box.musculoEsqueleticas': 'Desconforto Músculo Esquelético',
  'box.cargaMental': 'Intensidade de Carga Mental',
  'boxAcoes.acoes': 'Ações',  
  'boxAcoes.naoProgramadas': 'Não programadas',
  'boxAcoes.emProgresso': 'Em progresso',
  'boxAcoes.concluidas': 'Concluídas',
  'boxAcoes.atrasadas': 'Atrasadas',
  'grafico.riscos': 'Riscos Ergonômicos',
  'grafico.compliance': 'Compliance',
  'grafico.musculoEsqueleticas': 'Desconforto Músculo Esquelético',
  'grafico.cargaMental': 'Intensidade de Carga Mental',
  'graficoDetalhe.musculoEsqueleticos': 'Músculo Esqueléticos',
  'graficoDetalhe.musculoEsqueleticosDias': 'Músculo Esqueléticos - Dias Perdidos',
  'graficoDetalhe.psicossociais': 'Psicossociais/Cognitivos',
  'graficoDetalhe.psicossociaisDias': 'Psicossociais/Cognitivos - Dias Perdidos',
  'graficoDetalhe.diagramaCorlett': 'Desconforto Músculo Esquelético',
  'graficoDetalhe.frequencia': 'Frequência',
  'graficoDetalhe.adesao': 'Adesão',
  'graficoDetalhe.blitzPreventivas': 'Blitz Preventivas',
  'graficoDetalhe.blitzCorretivas': 'Blitz Corretivas',
  'boxDetalhe.pouca': '(0 - 19.9) Pouca',
  'boxDetalhe.alguma': '(20 - 39.9) Alguma',
  'boxDetalhe.moderada': '(40 - 59.9) Moderada',
  'boxDetalhe.bastante': '(60 - 79.9) Bastante',
  'boxDetalhe.intoleravel': '(80 - 100) Intolerável',
  'notify.baixando': 'Baixando',
  'notify.erroDownload': 'Não foi possível exportar o documento.',
  'graficoDetalhe.escala': 'Escala de Classificação'
}

export const lb_planoDeAcao = {
  'boxAcoes.acoes': 'Ações',  
  'boxAcoes.naoProgramadas': 'Não programadas',
  'boxAcoes.emProgresso': 'Em progresso',
  'boxAcoes.concluidas': 'Concluídas',
  'boxAcoes.atrasadas': 'Atrasadas',
  'boxAcoes.alterarStatus': 'Alterar Status',
  'grafico.acoes': 'Ações concluídas por período',
  'box.acoes.nenhuma': 'Nenhuma ação encontrada...',
  'graficoDetalhe.acoes': 'Ações concluídas',
  'tabela.programada': 'Programada',
  'notify.erroBusca': 'Ocorreu um erro ao buscar todas as ações',
  'notify.erroAcoes': 'Ocorreu um erro ao buscar as ações programadas por periodo.',
  'notify.acaoNaoEncontrada': 'Nenhuma ação encontrada',
  'notify.erroAcao': 'Ocorreu um erro ao buscar as ações',
  'tabela.todas': 'Plano de ação',
  'tabela.emProgresso': 'Ações em progresso',
  'tabela.novo': 'Ações não programadas',
  'tabela.atrasado': 'Ações atrasadas'
}

export const lb_coergo = {
  'boxAcoes.acoes': 'Ações',  
  'boxAcoes.naoProgramadas': 'Não programadas',
  'boxAcoes.emProgresso': 'Em progresso',
  'boxAcoes.concluidas': 'Concluídas',
  'boxAcoes.atrasadas': 'Atrasadas',
  'boxAcoes.programada': 'Programada',
  'boxAcoes.gerenciarAcoes': 'Gerenciar ações',
  'box.acoes.nenhuma': 'Nenhuma ação encontrada...',
  'tabela.titulo': 'Avisos',
  'atas.button': 'Atas',
  'grafico.acoes': 'Ações concluídas por período',
  'modal.detalheEquipe.titulo': 'Detalhe',
  'modal.detalheEquipe.nome': 'Nome',
  'modal.detalheEquipe.cargo': 'Cargo',
  'modal.detalheEquipe.email': 'Email',
  'modal.detalheAtas.titulo': 'Lista de Atas',
  'modal.detalheAtas.data': 'Data',
  'modal.detalheAtas.nome': 'Nome',
  'modal.detalheAtas.descricao': 'Descrição',
  'modal.detalheAtas.download': 'Download',
  'graficoDetalhe.acoes': 'Ações concluídas',
  'notify.buscaAcoes': 'Ocorreu um erro ao buscar todas as ações',
  'notify.buscaPeriodo': 'Ocorreu um erro ao buscar as ações programadas por periodo.',
  'notify.nenhumaAcao': 'Nenhuma ação encontrada',
  'notify.erroBusca': 'Ocorreu um erro ao buscar as ações',
  'notify.baixando': 'Baixando',
  'notify.erroDownload': 'Não foi possível realizar o download',
  'tabela.todas': 'Ações Comitê',
  'tabela.emProgresso': 'Ações em progresso',
  'tabela.novo': 'Ações não programadas',
  'tabela.atrasado': 'Ações atrasadas'
}

export const lb_homeOffice = {
  'box.questPreenchidos': 'Questionários preenchidos',  
  'box.questValidados': 'Questionários validados',  
  'box.mobiliarios': 'Mobiliários',
  'box.equipamentos': 'Equipamentos',
  'box.ambientais': 'F. Ambientais',
  'box.organizacionais': 'F. Organizacionais',
  'box.compliance': 'Compliance',
  'box.absenteismos: ': 'Absenteísmos',
  //'box.musculoEsqueleticas': 'Queixas Músculo Esqueléticas (Corlett)',
  'box.cargaMental': 'Intensidade de Carga Mental',
  'grafico.visaoGeral': 'Visão Geral',
  'grafico.compliance': 'Compliance',
  'grafico.corlett': 'Desconforto Músculo Esquelético',
  'grafico.cargaMental': 'Intensidade de Carga Mental',
  'boxDetalhe.pouca': '(0 - 19.9) Pouca',
  'boxDetalhe.alguma': '(20 - 39.9) Alguma',
  'boxDetalhe.moderada': '(40 - 59.9) Moderada',
  'boxDetalhe.bastante': '(60 - 79.9) Bastante',
  'boxDetalhe.intoleravel': '(80 - 100) Intolerável',
  'boxDetalhe.poucaOnly': 'Pouca',
  'boxDetalhe.algumaOnly': 'Alguma',
  'boxDetalhe.moderadaOnly': 'Moderada',
  'boxDetalhe.bastanteOnly': 'Bastante',
  'boxDetalhe.intoleravelOnly': 'Intolerável',
  'tooltip.corlett': 'Colaboradores',
  //'tooltip.boxConforme': 'Conforme',
  //'tooltip.boxNaoConforme': 'Não Conforme'
  'tooltip.satisfatorio': 'Satisfatório',
  'tooltip.naoSatisfatorio': 'Não satisfatório',
  'graficoDetalhe.escala': 'Escala de Classificação'
}

export const lb_documentos = {
  'modal.titulo': 'Documentos',
  'modal.nenhum': 'Nenhum arquivo encontrado...',
  'notify.erroLista': 'Não foi possível buscar a lista de documentos',
  'notify.baixando': 'Baixando',
  'notify.erroDownload': 'Não foi possível realizar o download'
}

export const lb_tabelas = {
  'cargo': 'Cargo',
  'cargos': 'Cargos',
  'data': 'Data',
  'localTrabalho': 'Local de Trabalho',
  'locaisTrabalho': 'Locais de Trabalho',
  'setor': 'Setor',
  'turno': 'Turno',
  'colaboradores': 'Colaboradores',
  'observacao': 'Observação',
  'gerg': 'GERG',
  'programacao' : 'Programação',
  'conclusao' : 'Conclusão',
  'qtdColaboradores': 'Qtd Colaboradores',
  'cargaMental': 'Carga Mental',
  'risco': 'Risco',
  'recomendacao': 'Recomendação',
  'acao': 'Ação',
  'condicao': 'Condição Anti Ergonômica',
  'atividadeAvaliada': 'Atividade Avaliada',
  'codigosNr17': 'NR 17',
  'codigosESocial': 'Código E-Social',
  'resultado': 'Resultado',
  'presenca': 'Presença',
  'ausencia': 'Ausência',
  'falta': 'Falta',
  'consultor': 'Consultor',
  'quantidade': 'Quantidade',
  'codigoCid': 'CID',
  'horasPerdidas': 'Horas Perdidas',
  'diasPerdidos': 'Dias Perdidos',
  'resultado.verde': 'Aceitável',
  'resultado.amarelo': 'Moderado',
  'resultado.vermelho': 'Alto',
  'resultado.roxo': 'Altíssimo',
  'dataProgramada': 'Data Programada',
  'dataConclusao': 'Data de Conclusão',
  'responsavel': 'Responsável',
  'prioridade': 'Prioridade',
  'riscoAtual': 'Risco Atual',
  'riscoInicial': 'Risco Inicial',
  'riscoEsperado': 'Risco Esperado',
  'matricula': 'Matrícula',
  'status': 'Status',
  'feedback': 'Feedback',
  'email': 'E-mail',
  'nome': 'Nome',
  'aprovado': 'Aprovado',
  'revisado': 'Revisado',
  'unidade': 'Unidade',
  'codigo': 'Codigo',
  'dataFormat': 'dd/MM/yyyy',
  'dataFormatMoment': 'DD/MM/YYYY',
  'atividade': 'Atividade',
  'motivo': 'Motivo',
  'manual': 'Metodologia',
  'custo': 'Custo',
  'custoTotal': 'Custo Total'
}

export const lb_menu = {
  'resumo': 'Resumo gerencial',
  'planoDeAcao': 'Plano de ação',
  'coergo': 'COERGO',
  'homeOffice': 'Home Office',
  'documentos': 'Documentos',
  'sair': 'Sair',
  'filtroPeriodo.ano': 'Ano',
  'filtroPeriodo.semestre': 'Semestre',
  'filtroPeriodo.trimestre': 'Trimestre',
  'filtroPeriodo.mes': 'Mês',
  'filtroPeriodo.periodo': 'Período',
  'tooltip.filtros': 'Filtros',
  'tooltip.abrirChamado': 'Abrir Chamado',
  'modal.title': 'Filtro de período',
  'header.coergo': 'COERGO - Comitê de Ergonomia',
  'label.nome': 'Nome Completo',
  'label.email': 'E-mail',
  'label.assunto': 'Assunto',
  'label.telefone': 'Telefone',
  'label.mensagem': 'Mensagem',
  'button.enviar': 'Enviar',
  'notify.preencherData': 'Para usar esta opção preencha os campos de data.',
  'notify.suporte': 'Chamado de suporte realizado com sucesso!',
  'notify.erroSuporte': 'Houve um erro ao realizar o chamado de suporte.',
  'label.cliente': 'Unidade',
  'treinamento': 'Treinamento',
  'tooltip.setores': 'Setores',
  'tooltip.cargos': 'Cargos',
  'tooltip.local': 'Local de Trabalho',
  'tooltip.dataInicial': 'Data Inicial',
  'tooltip.dataFinal': 'Data Final',
  'tooltip.limpar': 'Limpar filtro',
  'tooltip.filtrar': 'Filtrar',
  'dataFormat': 'dd/MM/yyyy',
  'grupoCliente': 'Exibir dashboard do grupo?',
  'tooltip.unidade': 'Unidade'
}

export const lb_questionario = {
  'mark.alta': 'Alta',
  'mark.media': 'Média',
  'mark.baixa': 'Baixa',
  'home.text1': 'Olá, seja bem vindo ao questionário para avaliação ergonômica!',
  'home.text2': 'Para continuar, informe seus dados:',
  'label.ghe': 'GERG',
  'label.turno': 'Turno',
  'label.cargo': 'Cargo',
  'label.setor': 'Setor',
  'label.localTrabalho': 'Local de Trabalho',
  'label.unidade': 'Unidade',
  'label.nome': 'Nome Completo',
  'label.email': 'E-mail',
  'label.assunto': 'Assunto',
  'label.telefone': 'Telefone',
  'label.matricula': 'Matrícula',
  'label.matriculacpf': 'Matrícula/CPF',
  'label.age': 'Idade',
  'label.nenhum': 'Nenhum',
  'label.weight': 'Peso',
  'label.height': 'Altura',
  'button.continuar': 'Continuar',
  'imgTitle': 'observe a imagem abaixo:',
  'videoTitle': 'Visualize o video abaixo para continuar:',
  'videoTitle1': ' Visualize o 1º video abaixo para continuar:',
  'videoTitle2': ' Visualize o 2º video abaixo para continuar:',
  'home.title1': 'Com base no seu trabalho homeoffice, responda:',
  'home.title2': ' Agora vamos falar sobre sua saúde. Em decorrência da execução de seu trabalho diário, você sente desconforto em alguma das regiões abaixo?',
  'button.ajuda': 'Ajuda',
  'home.titleImc': 'Análise do índice de massa corporal',
  'home.title3': 'Precisamos fazer algumas perguntas relacionadas ao seu nível carga mental:',
  'home.requestOne': 'Pratica exercicío físico?',
  'home.requestTwo': 'Qual?',
  'home.requestThree': 'Qual frequência?',
  'home.frequency': 'de 1 à 2 vezes na semana',
  'home.frequencyTwo': 'de 3 à 5 vezes na semana',
  'home.frequencyThree': 'mais que 5 vezes na semana',
  'home.yes': 'Sim',
  'home.no': 'Não',
  'home.frequencyLabel': 'Frequência',
  'home.activity' : 'Exercício Físico',
  //'nasa.fase1': 'Fase 1:',
  //'nasa.fase2': 'Fase 2:',
  //'home.title4': 'Agora, selecione nos pares abaixo qual o fator que representa uma maior contribuição à carga de trabalho para a sua atividade de acordo com as respostas da Fase 01.',
  'home.title5': 'Escolha uma das duas alternativas:',
  'nasa.opcao0': 'Exigência Mental',
  'nasa.opcao1': 'Exigência Física',
  'nasa.opcao2': 'Exigência Temporal',
  'nasa.opcao3': 'Desempenho',
  'nasa.opcao4': 'Esforço',
  'home.title6': 'muito obrigado por responder ao questionário.',
  'home.title7': 'Precisamos de uma imagem de seu posto de trabalho para finalizar.',
  'home.title88': 'Segue uma imagem modelo de um posto de trabalho:',
  'button.foto1': 'Escolher foto',
  'button.foto2': 'Tirar Foto',
  'dropzone': 'Clique para selecionar uma imagem',
  'home.title8': 'Obrigado pelo seu tempo !',
  'button.anterior': 'Anterior',
  'button.finalizar': 'Finalizar',
  'modal.descricao': 'Descrição:',
  'notify.alertaCam': 'Será necessário uma foto no final, por favor permita o acesso!',
  'notify.semCam': 'CAMERA não detectada, não será possível finalizar sem ela!',
  'notify.erroSubmit': 'Preencha todos os campos!',
  'notify.erroQuest': 'Responda todos os questionarios !',
  'notify.avisoFoto': 'Tire/escolha uma foto para finalizar!',
  'notify.erroImg': 'Erro no envio de imagem!',
  'notify.cpf': 'CPF Inválido!',
  'notify.email': 'E-mail Inválido!',
  'home.explicaNasa': 'A avaliação que você está prestes a realizar foi desenvolvida pela NASA, é uma técnica para avaliar a importância relativa de seis fatores na determinação de quanta carga de trabalho você é submetido para concluir suas tarefas. Esses seis fatores estão definidos na tabela abaixo, leia-os para ter certeza de que entendeu o que cada fator significa.',
  'home.tableTitle': 'Definições das 6 dimensões - Utilize os conceitos de relação abaixo para responder o questionário',
  'home.variaveis': 'Variáveis',
  'home.definicoes': 'Definições',
  'home.relacao': 'Relação',
  'home.fase1Title1': 'Agora você será apresentado a uma série de pares de fatores de escala de classificação.',
  'home.fase1Title2': 'Para cada par, escolha o fator que é mais importante para a sua experiência da carga de trabalho na tarefa que você executa.',
  'home.fase2Title1': 'Agora você verá uma série de escalas de avaliação. Para cada uma das seis escalas, avalie a tarefa que você executa tocando/clicando na localização das escalas que corresponda à sua experiência.',
  'home.fase2Title2': 'Cada linha possui dois extremos que descrevem a escala. Considere suas respostas cuidadosamente ao distinguir entre as diferentes condições de tarefas e considere cada escala individualmente',
  'notify.erroLink': 'Link incompleto!',
  'home.termoClick': 'Ao clicar em Continuar, você confirma estar de acordo com os termos de uso ',
  'home.click': 'clique para visualizar',
  'home.termo1': 'Prezado Colaborador',
  'home.termo2': 'O presente treinamento/formulário tem como objetivo orienta-lhe sobre as medidas básicas de saúde/ergonomia a serem tomadas para que você trabalhe em um ambiente saudável.',
  'home.termo3': 'As questões que serão realizadas, vão mapear as condições do seu posto de trabalho Home Office, bem como sua saúde, com questões relacionadas ao desconforto postural e o seu nível de carga mental com o objetivo de lhe disponibilizar os acessórios necessários para que seu ambiente seja saudável.',
  'home.termo4': 'Ao final do formulário será necessário a imputação de uma foto do seu posto de trabalho para que nossa equipe de especialistas efetue a avaliação ergonômica e proponha as melhorias necessárias à você.',
  'home.termo5': 'Há um termo de confidencialidade e sigilo de informações, garantindo a não divulgação de seus dados a outrem, a não ser os administradores/gestores do contrato.',
  'home.termo6': 'Desejamos um ótimo treinamento!',
  'home.termo7': 'Termo de aceite',
  'home.termo8': 'Declaro, por meio deste termo, que concordei em ser entrevistado(a) e/ou participar do estudo ergonômico do meu posto de trabalho Home Office.',
  'home.termo9': 'Afirmo que aceitei participar por minha própria vontade, sem receber qualquer incentivo financeiro ou ter qualquer ônus e com a finalidade exclusiva de colaborar para o sucesso do estudo ergonômico.',
  'home.termo10': 'Fui também esclarecido(a) de que os usos das informações por mim oferecidas estão submetidos às avaliações ergonômicas necessárias.',
  'home.termo11': 'O acesso e a análise dos dados coletados se farão apenas pelos Ergonomistas Alicrim e Ergonomistas e Gestores contratuais de minha empresa.',
  'label.digite': 'Digite para pesquisar'
}

export const lb_modal = {
  'modalPlano.opcao1': 'Plano de ação em progresso',
  'modalPlano.opcao2': 'Plano de ação não programado',
  'modalPlano.opcao3': 'Plano de ação concluído',
  'modalPlano.buttonTitle': 'Plano de ação',
  'modalPlano.recomendacao': 'Redecomendação',
  'modalPlano.buttonAtual': 'Atualizar status dos planos selecionados',
  'modalPlano.novoStatus': 'Novo Status',
  'modalPlano.buttonSalvar': 'Salvar',
  'modalPlano.atualizar': 'Atualizar',
  'modalPlano.coergo': 'COERGO',
  'modalPesquisa.imgPosto': 'Imagem do posto de trabalho',
  'modalPesquisa.pergunta': 'Pergunta',
  'modalPesquisa.resposta': 'Resposta',
  'modalPesquisa.categoria': 'Categoria',
  'modalPesquisa.corlett': 'Corlett',
  'modalPesquisa.regiao': 'Região do corpo',
  'modalPesquisa.nivel': 'Nível de dor',
  'modalPesquisa.resultado': 'Resultado',
  'modalPesquisa.parVar': 'Pares das variáveis',
  'modalPesquisa.var1': 'Variável 1',
  'modalPesquisa.var2': 'Variável 2',
  'modalPesquisa.revisado': 'Revisado por',
  'modalPesquisa.dataRev': 'Data da revisão',
  'modalPesquisa.obs': 'Observações da revisão',
  'modalPesquisa.concluido': 'Concluído',
  'modalPesquisa.nConcluido': 'Não concluído',
  'modalPesquisa.dados': 'Dados pessoais',
  'modalPesquisa.perguntas': 'Perguntas',
  'modalPesquisa.nasa': 'NASA/TLX',
  'modalPesquisa.revisao': 'Revisão',
  'modalPesquisa.itensPorPag': 'Itens por páginas',
  'notify.statusAtualizado': 'Status atualizado com sucesso.',
  'notify.falhaStatus' :'Falha ao atualizar status.',
  'modalPlano.filtrar': 'Filtrar status...',
  'modalPalno.acaoInvalida': 'Data invalida!',
  'modalInitial': 'Selecione um unidade!',
  'modalPesquisa.titleCorlett': 'Desconforto Músculoesquelético',
  'modalPesquisa.titleNasa': 'Intensidade de Carga Mental',
  'modalPesquisa.titleCoergo': 'Plano de Ação do Comitê',
  'modalPesquisa.titleAcao': 'Plano de Ação',
  'modalPesquisa.compliance': 'Compliance',
  'modalPesquisa.exportar': 'Exportar para PDF',
  'modalPesquisa.exportar-xls': 'Exportar para XLS',
  'notify.dataObrigatorio': 'Data é obrigatório.',
  'notify.responsavelObrigatorio': 'Responsável é obrigatório.',
  'notify.dataMenorHoje': 'Data deve ser maior que a data atual.',
}

export const lb_treino = {
  'video1.titulo': 'Módulo 1',
  'video1.subTitulo': 'Treinamento em ergonomia',
  'mostrarNavegacao': 'Mostrar navegação',
  'esconderNavegacao': 'Esconder navegação',
  'videos': 'Videos'
}