import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Material } from './styles';
import {
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    // Paper,
    // Table,
    Grid,
    // TextField,
    // TablePagination,
    // ButtonGroup,
    Button,
    Modal,
    Paper
    // Typography,
    // Typography,
	// Button,
	// Modal
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@material-ui/icons/Close';
// import Tooltip from '../TooltipCustom'
// import { format, addDays } from 'date-fns'
// import moment from 'moment'
// import excel from '../../../../../../assets/images/excel.png'
// import pdf from '../../../../../../assets/images/pdf.png'
// import DashboardService from '../../../../../../services/Dashboard';
// import { saveAs } from 'file-saver';
// import Loader from 'react-loader-spinner';
// import { lb_modal, lb_tabelas } from '../../../../../../libs/labels';
// import { lb_modalEn, lb_tabelasEn } from '../../../../../../libs/labelsEn';
// import { lb_modalEs, lb_tabelasEs } from '../../../../../../libs/labelsEs';
import './modal-nasa.css'
// import api from '../../../../../../services/api'
import QuestionarioNasa from '../QuestionarioNasa'

// var time = null;

class ModalNasa extends Component {
    state = {
        filterTable: ['','','','','','','',''],
        loadExport:false,
        rowsPerPage: 50,
        page: 0,
        data: {},
        resultados: [],
        lb: {},
        lb_tabelas: {},
        columns:[],
        isOpen:false,
        modalPatern: false,
        row:{}
    }

    componentWillMount = () => {

    }

    render() {
        // eslint-disable-next-line
        const { modalPatern, handleCloseModal, row, classes } = this.props;
        return (
            <div>
                {modalPatern &&  
                <Modal open={modalPatern} >
                        <Grid style={{backgroundColor: '#fff', width: '100%', height: '100%', outline: 'none'}}>
                            <Grid container justify='space-between' alignItems='center' style={{width: '100%', height: '5%'}}>
                                <Grid></Grid>
                                <Button onClick={handleCloseModal}><CloseIcon/></Button>
                            </Grid>

                            <Paper className={classes.tablePaper} style={{height: '93.5vh'}}>
                           
                             {row && <QuestionarioNasa  apiKey={row.id} ></QuestionarioNasa>} 
                            </Paper>

                        </Grid>
                    </Modal>
                }
             </div>
        );
    }
}
export default (withStyles(Material)(ModalNasa));