import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NotificationActions from '../../../../store/ducks/notifier';
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  CircularProgress
}from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import api from '../../../../services/api'
import { Material } from './styles';
import './home.css'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // mask={['(', /[0-9]/, /[0-9]/, ')', ' ' , /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        placeholderChar={'\u2000'}
      />
    );
}

function TextMaskTelCustom(props) {
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // mask={['(', /[0-9]/, /[0-9]/, ')', ' ' , /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        placeholderChar={'\u2000'}
      />
    );
}


TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskTelCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Home extends Component {
  state = {
    falhaApiKey: false,
    lang: this.props.lang,
    listService: [],
    inputService: '',
    service:null,
    serviceSelec: ''
  }

  async componentWillMount() {
    this.fetchService();
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputService, service } = this.state;

    if (inputService === '' && inputService !== prevState.inputService) {
      this.setState({listService: service});
    }else if(inputService !== prevState.inputService) {
      this.filterService()
    }
  }


  filterService = () => {
    const { service, inputService } = this.state
    const rawList = service.filter(item => {
      const valorMinusculo = inputService.toLowerCase()
      const cidadeMinusculo = item.descricao.toLowerCase()
      if(cidadeMinusculo.includes(valorMinusculo)){
        return item
      }
      return null;
    })
    this.setState({listService: rawList})
  }

  fetchService = async() => {
    try{
      const {data} = await api.get(`/marketing/services/`)
      // console.log('services',data)
      this.setState({service: data})
      this.setState({listService:data})
    }catch(err){
      console.log(err)
    }
  }

  handleSubmit = async(event) => {
    const {nome,
      empresaNome,
      unidade,
      cargo,
      telefone,
      celular,
      colaboradores,
      email,
      serviceSelec
    } = this.state;
    const {notify} = this.props;

    if(nome === null 
      || empresaNome === null
      || cargo === null
      || (telefone === null && celular === null)
      || email === null){
      notify("Preencha todos os campos!", { variant: 'error' });
      return
    }

    if(!this.isEmail(email)) {
      notify("E-mail Inválido!", { variant: 'error' });
      return
    }

   
    return this.props.next({nome, 
      empresaNome, 
      unidade, cargo,
      telefone, 
      celular, 
      colaboradores,
      email,
      serviceSelec
      })
  }


  changeService = value => {
    // console.log('changeService', value.target)
    this.setState({inputService: value.target.value})
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  isEmail(email){

    let usuario = email.substring(0, email.indexOf("@"));
    let dominio = email.substring(email.indexOf("@")+ 1, email.length);

    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")===-1) &&
        (dominio.search("@")===-1) &&
        (usuario.search(" ")===-1) &&
        (dominio.search(" ")===-1) &&
        (dominio.search(".")!==-1) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true;
    } else {
        return false
    }

  }


  

  render() {
      const {
        falhaApiKey,
        nome,
        empresaNome,
        unidade,
        cargo,
        // telefone,
        celular,
        colaboradores,
        email,
        listService
      } = this.state;
      const {
        classes
      } = this.props;
    return (
      <Grid container className="content-quiz">
        <div className="container-content">
          <div className="container-squad"> 
            <div className="title-squad">
              <span>
                PREENCHA OS DADOS ABAIXO PARA RECEBER GRATUITAMENTE O E-BOOK
              </span>
            </div>
            <Grid item className="content-grid">
              <TextField
                label={"Nome Completo *"}
                className={classes.textfield}
                name="nome"
                id="nome"
                value={nome}
                onChange={value => this.setState({nome: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
              <TextField
                label={"Empresa *"}
                className={classes.textfield}
                name="empresaNome"
                id="empresaNome"
                value={empresaNome}
                onChange={value => this.setState({empresaNome: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
              <TextField
                label={"Unidade"}
                className={classes.textfield}
                name="unidade"
                id="unidade"
                value={unidade}
                onChange={value => this.setState({unidade: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
              <TextField
                label={"Cargo"}
                className={classes.textfield}
                name="cargo"
                id="cargo"
                value={cargo}
                onChange={value => this.setState({cargo: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
            <TextField
                label={"E-mail *"}
                className={classes.textfield}
                name="email"
                id="email"
                value={email}
                onChange={value => this.setState({email: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
              <TextField
                label={"Celular *"}
                className={classes.textfield}
                name="celular"
                id="formatted-text-mask-input"
                value={celular}
                onChange={this.handleChange('celular')}
                variant="outlined"
                fullWidth
                size="small"
                
                InputProps={{
                  inputComponent: TextMaskCustom
                }}
              />  
              {/* <TextField
                label={"Telefone"}
                className={classes.textfield}
                name="telefone"
                id="formatted-text-mask-input"
                value={telefone}
                // onBlur={value => this.mascaraDeTelefone(value)}
                onChange={this.handleChange('telefone')}
                // onFocus={value => this.tiraHifen(value)}
                variant="outlined"
                fullWidth
                
                InputProps={{
                  inputComponent: TextMaskTelCustom
                }}
              />   */}
              <TextField
                label={"Nº de colaboradores"}
                className={classes.textfield}
                name="colaboradores"
                id="colaboradores"
                value={colaboradores}
                onChange={value => this.setState({colaboradores: value.target.value})}
                variant="outlined"
                type="email"
                fullWidth
                size="small"
              /> 
              <FormControl className={classes.formControl} fullWidth variant="outlined">
                <Autocomplete
                size="small"
                  id="combo-box-demo-8"
                  onChange={ (value, option) => this.setState({serviceSelec: option === null ? '' : option}) }
                  options={listService}
                  getOptionLabel={option => (option.description === null ? '' : option.description)}
                  renderInput={serviceSelec => (
                    <TextField {...serviceSelec} label={"Serviços"} variant="outlined" fullWidth onChange={this.changeService}/>
                  )}
                  noOptionsText={"Digite para pesquisar"}
                />
              </FormControl>
              <div className ="content-btn">
                <div className="btn-down">
                  <Button
                    className="down"
                    fullWidth
                    disabled={falhaApiKey}
                    variant="contained"
                    style={{color: '#fff'}}
                    onClick={() => this.handleSubmit()}
                  >
                    {falhaApiKey?
                      <CircularProgress size={25} /> : "Baixar o e-book"
                    }
                  </Button>
                
                  <span className="span-btn">
                    Enviaremos o conteúdo por e-mail
                  </span>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
)(Home);

