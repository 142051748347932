import api from '../api';
import {apiReport} from '../api';
import axios from 'axios';

export default class DashboardService {
  static async getPreferences() {
    let preferences = JSON.parse(localStorage.getItem('@alicrim:dashboard'));
    if (!preferences) {
      preferences = {};

      let clienteSelecionado = '';
      let setoresSelecionados = [];
      let cargosSelecionados = [];
      let locaisSelecionados = [];
      let unidadesSelecionadas = [];
      let dataInicial = '';
      let dataFinal = '';
      let grupocliente = false;
      let periodo = 'MES';
      let lang = 'pt_BR'

      preferences.clienteSelecionado = clienteSelecionado;
      preferences.setoresSelecionados = setoresSelecionados;
      preferences.cargosSelecionados = cargosSelecionados;
      preferences.locaisSelecionados = locaisSelecionados;
      preferences.unidadesSelecionadas = unidadesSelecionadas;
      preferences.dataInicial = dataInicial;
      preferences.dataFinal = dataFinal;
      preferences.grupocliente = grupocliente;
      preferences.periodo = periodo;
      preferences.lang = lang;
    }
    return preferences;
  }

  static async setPreferences(preferences) {
    localStorage.setItem('@alicrim:dashboard', JSON.stringify(preferences));
  }

  static async getClientes(usuario) {
    const { data } = await api.get(`/controller/cliente?usuario=${usuario}`);

    return data;
  }

  static async getSetorPorCliente(cliente) {
    const { data } = await api.get(`/controller/setor?cliente=${cliente}`);

    return data;
  }

  static async getCargosPorCliente(cliente) {
    const { data } = await api.get(`/controller/cargo?cliente=${cliente}`);

    return data;
  }

  static async getLocalDeTrabalhoPorCliente(cliente) {
    const { data } = await api.get(`/controller/local?cliente=${cliente}`);

    return data;
  }

  static async getUnidadePorCliente(cliente) {
    const { data } = await api.get(`/controller/unidade?cliente=${cliente}`);

    return data;
  }

  static async getResultadosGerais(consultor, cliente, setores, cargos, grupoCliente, inicial) {
    const { data } = await api.get(`/dashboard/resultadosgerais?consultor=${consultor}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupoCliente=${grupoCliente}&inicial=${inicial}`);

    return data;
  }

  static async getDetalheResultadosGerais(usuario, cliente, setores, cargos, resultado, inicial) {
    const { data } = await api.get(`/dashboard/resultadosgerais/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&resultado=${resultado}&inicial=${inicial}`);

    return data;
  }

  static async getGraficoAfastamentos(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/afastamentos?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getDetalheAfastamentos(usuario, cliente, setores, cargos, grupocliente, tipo, periodo) {
    const { data } = await api.get(`/dashboard/afastamentos/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&tipo=${tipo}&periodo=${periodo}`);

    return data;
  }

  static async getGraficoDiasPerdidos(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/diasperdidos?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getGraficoPlanoAcao(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/planoacao?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getGraficoAcoesMensais(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/acoesmensais?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getTarefasAtribuidasPorSetor(usuario, cliente, setores, cargos, grupocliente, status) {
    const { data } = await api.get(`/dashboard/planoacao/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&status=${status}`);

    return data;
  }

  static async getAcoesMensaisDetalhe(usuario, cliente, setores, cargos, grupocliente, periodo, status) {
    const { data } = await api.get(`/dashboard/planoacao/acoesmensais/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&periodo=${periodo}&status=${status}`);

    return data;
  }

  static async getRelatorioCompliance(usuario, cliente, setores, cargos, grupocliente, inicial) {
    const { data } = await api.get(`/dashboard/relatoriocompliance?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&inicial=${inicial}`);

    return data;
  }

  static async getDetalheRelatorioCompliance(usuario, cliente, setores, cargos, grupocliente, compliance, inicial) {
    const { data } = await api.get(`/dashboard/relatoriocompliance/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&compliance=${compliance}&inicial=${inicial}`);

    return data;
  }

  static async getQuestionarioSensoFrequencia1(usuario, cliente, setores, cargos, grupocliente, dataInicialProergo, dataFinalProergo, tipoResposta) {
    const { data } = await api.get(`/dashboard/jobstress?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&tipoResposta=${tipoResposta}`);

    return data;
  }

  static async getGraficoPep(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/pep/adesao?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getDetalhePep(usuario, cliente, setores, cargos, grupocliente, periodo) {
    const { data } = await api.get(`/dashboard/pep/adesao/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&periodo=${periodo}`);

    return data;
  }

  static async getBlitzPostura(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/blitz?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getDetalheBlitz(usuario, cliente, setores, cargos, grupocliente, tipo, periodo) {
    const { data } = await api.get(`/dashboard/blitz/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&tipo=${tipo}&periodo=${periodo}`);

    return data;
  }

  static async getGraficoSenso(usuario, cliente, setores, cargos, grupocliente, inicial, labelPeriodo) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/sensoergonomico`, {usuario, cliente, setores, cargos, grupocliente, inicial, labelPeriodo, ...filter});

    return data;
  }

  static async getRegioesCorpo(tipo) {
    const { data } = await api.get(`/dashboard/regioescorpo?tipo=${tipo}`);

    return data;
  }

  static async getGraficoCargaMental(usuario, cliente, setores, cargos, grupocliente, inicial) {
    const { data } = await api.get(`/dashboard/nasa?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&inicial=${inicial}`);

    return data;
  }

  static async getGraficoCoergo(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/coergo/?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }

  static async getGraficoAcoesMensaisCoergo(usuario, cliente, setores, cargos, grupocliente) {
    const { data } = await api.get(`/dashboard/coergo/acoesmensais?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}`);

    return data;
  }  

  static async getTarefasAtribuidasPorSetorCoergo(usuario, cliente, setores, cargos, grupocliente, status) {
    const { data } = await api.get(`/dashboard/coergo/detalhe?usuario=${usuario}&cliente=${cliente}&setores=${setores}&cargos=${cargos}&grupocliente=${grupocliente}&status=${status}`);

    return data;
  }  

  static async getHomeTotalQuestionarios() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/total`, filter);

    return data;
  }

  static async getHomeResultadoCategoria(categoria) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/resultado/categoria/${categoria}`, filter);

    return data;
  }

  static async getHomeResultadoCompliance() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/resultado/`, filter);

    return data;
  }

  static async getHomeResultadoSenso() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/resultado/senso/`, filter);

    return data;
  }

  static async getHomeResultadoNasa() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/resultado/nasa/`, filter);

    return data;
  }

  static async getVisaoGeral() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/visaogeral`, filter);

    return data;
  }

  static async getVisaoSenso() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/visaogeral/senso`, filter);

    return data;
  }

  static async getVisaoNasa() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/visaogeral/nasa`, filter);

    return data;
  }

  static async getHomeGraficoCategoria(opcao) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/detalhe/${opcao}`, filter);

    return data;
  }

  static async getDetalheNasa() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/detalhe/nasa`, filter);

    return data;
  }

  static async getDetalheSenso(search) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/detalhe/senso`, {...search, ...filter});

    return data;
  }

  static async getDetalheCompliance() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/grafico/detalhe/compliance/`, filter);

    return data;
  }

  static async getGraficoCompliance() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/compliance`, filter);

    return data;
  }

  static async getDetalhePesquisa(search) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/detalhe/grafico/`, {...search, ...filter});

    return data;
  }

  static async getDetalheOverview(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();

    let filterDTO = {
      cliente: filter.cliente,
      period: search.periodClean,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      satisfactory: search.satisfactory,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/overview/`, filterDTO);

    return data;
  }

  static async getDetalheNasaHomeOffice(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();

    let filterDTO = {
      cliente: filter.cliente,
      period: search.periodClean,
      lang: filter.lang,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/mentalcharge/`, filterDTO);

    return data;
  }

  static async getDetalheNasaSummary(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();

    let filterDTO = {
      cliente: filter.cliente,
      period: search.periodClean,
      lang: filter.lang,
      positions: filter.cargos,
      units: filter.unidade,
      sectors: filter.setores,
      workLoactions: filter.localDeTrabalho,
      customerGroup: filter.grupoCliente
    };

    const { data } = await api.post(`/mentalchargesummary`, filterDTO);

    return data;
  }

  static async getDetalheFurniture(search) {

    let filter = await this.getFilter();

    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      discriptionQuestion: search.periodClean,
      response: search.satisfactory, 
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/homequestionario/furniture/`, filterDTO);

    return data;
  }

  static async getDetalheEquipment(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      discriptionQuestion: search.periodClean,
      response: search.satisfactory, 
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/homequestionario/equipment/`, filterDTO);

    return data;
  }

  static async getDetalheEnvironmental(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      discriptionQuestion: search.periodClean,
      response: search.satisfactory, 
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/homequestionario/environmental/`, filterDTO);

    return data;
  }

  static async getDetalheOrganizational(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      discriptionQuestion: search.periodClean,
      response: search.satisfactory, 
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/homequestionario/organizational/`, filterDTO);

    return data;
  }

  static async getDetalheMentalCharge(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/mentalchargelist`, filterDTO);

    return data;
  }

  static async getDetalheMentalChargeSummary(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: filter.cargos,
      units: filter.unidade,
      sectors: filter.setores,
      workLoactions: filter.localDeTrabalho,
      customerGroup: filter.grupoCliente
    };

    const { data } = await api.post(`/mentalchargesummarylist`, filterDTO);

    return data;
  }

  static async getDetalheMentalChargeSummaryAll(search) {
    // // console.log('search',search)
    // let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: search.cliente,
      dateInitial: null,
      dateFinal: null,
      period: null,
      lang: 'pt_BR',
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      positions: null,
      units: null,
      sectors: null,
      workLoactions: null,
      customerGroup: false
    };

    const { data } = await api.post(`/mentalchargesummarylistall`, filterDTO);

    return data;
  }

  static async getDetalheMusculoskeletalDiscomfort(search) {
    // // console.log('search',search)
    let filter = await this.getFilter();
    
    let filterDTO = {
      cliente: filter.cliente,
      dateInitial: filter.dataInicial,
      dateFinal: filter.dataFinal,
      period: filter.periodo,
      lang: filter.lang,
      offset: search.offset,
      rowsPerPage: search.rowsPerPage,
      response: search.nivelDeDor,
      request: search.regiaoCorpo,
      positions: filter.cargos,
      units: filter.unidade
    };

    const { data } = await api.post(`/musculoskeletaldiscomfort`, filterDTO);

    return data;
  }

  static async getDetalheOverviewReport(search) {
    let filter = await this.getFilter();
    const { data } = await apiReport.get(`/homeoffice/base64/xls/${filter.cliente}/${search.periodClean}/${search.satisfactory}`);

    return data;
  }

  static async getDetalheOverviewReportSigle(id) {
    let filter = await this.getFilter();
    // // console.log(apiReport.baseURL)
    const { data } = await apiReport.get(`/homeoffice/cliente/base64/xls/${filter.cliente}/${id}`);

    return data;
  }

  static async getGraficoRiscosErgonomicos() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/riscoergonomico`, filter);

    return data;
  }

  static async getDadosRiscosErgonomicos() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/riscoergonomico`, filter);

    return data;
  }

  static async getDadosCompliance() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/compliance`, filter);
    return data;
  }

  static async getMedia(id) {
    //let filter = await this.getFilter();
    const preferences = await this.getPreferences();
    const { data } = await api.get(`/ho/dashboard/detalhe/nasa/${id}?lang=${preferences.lang}`);

    return data;
  }

  static async listCorlett(id) {
    const { data } = await api.get(`/homequestionario/corlett/${id}`);

    return data;
  }

  static async listQuestions(id) {
    const { data } = await api.get(`/homequestionario/questions/${id}`);

    return data;
  }

  static async listNasaFirst(id) {
    const { data } = await api.get(`/homequestionario/nasa/first/${id}`);

    return data;
  }

  static async listNasaSecond(id) {
    const { data } = await api.get(`/homequestionario/nasa/second/${id}`);

    return data;
  }

  static async getFilter() {
    const preferences = await this.getPreferences();
    const usuario = JSON.parse(localStorage.getItem('@alicrim:usuario'));

    let filter = {};

    filter.periodo = preferences.periodo || 'MES';
    filter.cliente = preferences.clienteSelecionado ? preferences.clienteSelecionado.codigo : undefined;
    filter.cargos = null;
    if (preferences.cargosSelecionados) {
      filter.cargos = preferences.cargosSelecionados.map(s => JSON.parse(s)).filter(s => s.id).map(s => s.id);
    }
    filter.setores = null;
    if (preferences.setoresSelecionados) {
      filter.setores = preferences.setoresSelecionados.map(s => JSON.parse(s)).filter(s => s.id).map(s => s.id);
    }
    filter.localDeTrabalho = null;
    if (preferences.locaisSelecionados) {
      filter.localDeTrabalho = preferences.locaisSelecionados.map(s => JSON.parse(s)).filter(s => s.id).map(s => s.id);
    }
    filter.unidade = null;
    if (preferences.unidadesSelecionadas) {
      filter.unidade = preferences.unidadesSelecionadas.map(s => JSON.parse(s)).filter(s => s.id).map(s => s.id);
    }
    filter.grupoCliente = preferences.grupocliente;
    filter.usuario = usuario.id;
    filter.dataInicial = preferences.dataInicial;
    filter.dataFinal = preferences.dataFinal;
    filter.lang = preferences.lang;
    return filter;
  }

  static async getAllAcoes() {
    const { cargos, dataInicial, dataFinal, ...filter } = await this.getFilter();
    
    const { data } = await api.post(`/dashboard/resultado/planoacao`, filter);
    return data;
  }

  static async getAcoesProgramadasPorPeriodo(params) {
    const { cargos, dataInicial, dataFinal, ...filter } = await this.getFilter();
    
    const { data } = await api.post(`/dashboard/calendario/acoesprogramadas`, filter, {
      params,
    });
    return data;
  }


  static async getAcoesPorData(params) {
    const { periodo, cargos, grupoCliente, dataInicial, dataFinal, ...filter } = await this.getFilter();
    
    const { data } = await api.post(`/dashboard/lista/acoes`, filter, {
      params,
    });
    return data;
  }

  static async getAcoesConcluidas() {
    const filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/acoesconcluidas`, filter);
    return data;
  }

  static async getDocumentos(id) {
    const { data } = await api.get(`/cliente/documentos/${id}`);
    return data;
  } 

  static async downloadDocumento(id) {
    const { data } = await api.get(`/cliente/documentos/download/${id}`, {
      responseType: 'arraybuffer',
    });
    return data;
  } 

  static async sendChamado(form) {
    // const filter = await this.getFilter();

    const { data } = await api.post(`/dashboard/chamado`, form);
    return data;
  } 

  static async allAcoes() {
    const filter = await this.getFilter();

    const { data } = await api.post(`/dashboard/lista/acoes/all`, filter);
    return data;
  } 

  static async getAcoesComite() {
    const filter = await this.getFilter();

    const { data } = await api.post(`/dashboard/resultado/coergo`, filter);
    return data;
  } 

  static async allAcoesComite() {
    const filter = await this.getFilter();

    const { data } = await api.post(`/dashboard/detalhe/resultado/acoescomite/`, filter);
    return data;
  } 


  static async saveStatusAcao(analises) {
    // const filter = await this.getFilter();
    // console.log('salvar acao', analises)
    const { data } = await api.post(`/dashboard/planoacao/salvar`, analises);
    return data;
  } 
  

  static async exportTo(nome, ext, form) {

    const { data } = await axios.post(`https://docs.v2saude.com.br/docs/${ext}`, form, {
      params: { nome },
      responseType: 'arraybuffer',
    });
    return data;
  } 


  static async getResultadoAfastamentoMe() {
    //let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/historicoafastamento/me`);

    return data;
  }

  static async getGraficoNasaVisaoGeral() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/nasa/grafico/visageral`, filter);

    return data;
  }
  
  static async getDashboardResultadoNasa(search) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/nasa/resultado`, {...search, ...filter});
    // // console.log({...search, ...filter})
    return data;
  }
  
  static async getDashboardDetalheNasa() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/nasa/detalhe`, filter);

    return data;
  }

  static async getResultadoHistoricoAfastamentoGeral() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/historicoafastamento/geral`, filter);

    return data;
  }

  static async getResultadoHistoricoAfastamentoME() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/historicoafastamento/me`, filter);

    return data;
  }

  static async getResultadoHistoricoAfastamentoPC() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/historicoafastamento/pc`, filter);

    return data;
  }

  static async getGraficoHistoricoAfastamento(tipo) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/historicoafastamento`, filter, { params: {codigoCid: tipo}});

    return data;
  }
  // eslint-disable-next-line
  static async getGraficoDiasPerdidos(tipo, periodo) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/lista/historicoafastamento`, filter, { params: {tipo: tipo, periodo: periodo}});

    return data;
  }

  static async getGraficoDiasPerdidosModal(tipo) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/diasperdidos`, filter, { params: {codigoCid: tipo}});

    return data;
  }

  static async getResultadoSenso() {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/resultado/senso`, filter);

    return data;
  }
  static async getGraficoSensoVisaoGeral() {
    let filter = await this.getFilter();
    // // console.log('filter', filter)
    const { data } = await api.post(`/dashboard/grafico/senso`, filter);

    return data;
  }
  
  static async getGraficoDetalheSenso(search) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/grafico/detalhe/senso`, {...search, ...filter});
  
    return data;
  }

  static async getGraficoDetalheSensoEspecifico(search) {
    let filter = await this.getFilter();
    filter.periodClean = search.periodClean;
    const { data } = await api.post(`/dashboard/grafico/detalhe/senso/especifico`,filter);
  
    return data;
  }

  static async getDetalheGrafico(search) {
    let filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/detalhe/grafico/senso/`, {...search, ...filter}); 

    return data;
  }

  static async getResultadoBlitz() {
    let filter = await this.getFilter()
    const { data } = await api.post(`/dashboard/resultado/blitz`, filter); 

    return data;
  }

  static async getResultadoPep() {
    let filter = await this.getFilter()
    const { data } = await api.post(`/dashboard/resultado/pep`, filter); 

    return data;
  }

  static async getDetalheGraficoCompliance(search) {
    let filter = await this.getFilter()

    const { data } = await api.post(`/dashboard/detalhe/grafico/compliance/`, {...search, ...filter}); 

    return data;
  }

  static async getDetalheGraficoRiscoErgonomico(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/detalhe/grafico/riscoergonomico/` ,  {...search, ...filter}); 

    return data;
  }

  static async getGraficoDetalheBlitz() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/blitz` , filter); 

    return data;
  }

  static async getDetalheGraficoBlitz(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/detalhe/blitz` , {...search, ...filter}); 

    return data;
  }

  static async getGraficoDetalhePep() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/pep` , filter); 

    return data;
  }

  static async getGraficoDetalhePepAdesao() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/pep/adesao`, filter); 

    return data;
  }

  static async getDetalheGraficoPep(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/detalhe/pep`, {...search, ...filter}); 

    return data;
  }

  static async getResultadoPlano() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/planoacao`, filter); 

    return data;
  }

  static async getDetalhePlano(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/planoacao/detalhe?status=${search}`, filter); 
 
    return data;
  }

  static async getDetalhePlanoNew(search) {
    let filter = await this.getFilter()    
    filter.offset = search.offset;
    filter.rowsPerPage = search.rowsPerPage;
    filter.periodClean = search.periodClean;

    // console.log('filter.periodClean', filter.periodClean)

    const { data } = await api.post(`/dashboard/planoacao/detalhe?status=${search.statusAcoes}`, filter); 
 
    return data;
  }

  static async getResultadoCoergo() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/`, filter); 

    return data;
  }

  static async getDetalheCoergo(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/detalhe?status=${search}`, filter); 

    return data;
  }

  static async getDetalheCoergoNew(search) {
    let filter = await this.getFilter()    
    filter.offset = search.offset;
    filter.rowsPerPage = search.rowsPerPage;
    filter.periodClean = search.periodClean;

    const { data } = await api.post(`/dashboard/coergo/detalheNew?status=${search.statusAcoes}`, filter); 

    return data;
  }

  static async getDetalheAviso() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/avisos`, filter); 

    return data;
  }

  static async getDetalheListaAviso(search) {
    let filter = await this.getFilter()    
    const { data } = await api.get(`/dashboard/coergo/aviso?id=${search}`, filter); 

    return data;
  }

  static async getDetalheGraficoNasa(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/nasa/grafico/detalhe`, {...filter, ...search}); 

    return data;
  }

  static async getDetalheGraficoSenso() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/senso/queixas`, filter); 

    return data;
  }

  static async getGraficoDetalheAfastamentos(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/total/afastamentos?codigocid=${search}`, filter); 

    return data;
  }

  static async getGraficoDetalheDiasPerdidos(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/grafico/total/diasperdidos?codigocid=${search}`, filter); 

    return data;
  }

  static async getGraficoComplianceHome() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/ho/dashboard/grafico/visaogeral/compliance/`, filter); 

    return data;
  }

  static async getDetalheGraficoAfastamentos(search, codigocid) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/detalhe/grafico/afastamentos`,  {...search, ...filter}, { params: {codigocid: codigocid}}); 

    return data;
  }

  static async getTarefasAtribuidasPorSetorDetalhe(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/planoacao/concluidas/detalhe/`,  {...search, ...filter}); 

    return data;
  }

  static async getEquipesCoergo() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/equipes`, filter); 

    return data;
  }

  static async getDetalheEquipesCoergo(id) {
    let filter = await this.getFilter()    
    const { data } = await api.get(`/dashboard/coergo/equipe?id=${id}`, filter); 

    return data;
  }

  static async getDetalheAtas() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/atas`, filter); 

    return data;
  }

  static async getDownloadAtas(id) {
    let filter = await this.getFilter()    
    const { data } = await api.get(`/dashboard/coergo/atas/download/${id}`, filter); 

    return data;
  }

  static async getGraficoNasaHomeOffice(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/ho/dashboard/grafico/detalhado/nasa`, {...search, ...filter}); 

    return data;
  }

  static async getGraficoDonutCoergo() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/grafico/donut`, filter); 

    return data;
  }
  
  static async getCalendarioCoergo(date) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/calendario/acoesprogramadas?ano=${date.ano}&mes=${date.mes}`, filter); 

    return data;
  }

  static async getCalendarioCoergoList(params) {
    const { periodo, cargos, grupoCliente, dataInicial, dataFinal, ...filter } = await this.getFilter()
    
    const { data } = await api.post(`/dashboard/coergo/lista/acoes`, filter, {
      params,
    });
    return data;
  }

  static async getAllAcoesCoergo() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/lista/acoes/all`, filter); 

    return data;
  }

  static async getAllAcoesCoergoSalvar(analises) {
    const { data } = await api.post(`/dashboard/coergo/salvar`, analises); 

    return data;
  }

  static async getGraficoDonutPlano() {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/planoacao/grafico/donut`, filter); 

    return data;
  }

  static async getDetalheConcluidosCoergo(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/dashboard/coergo/concluidas/detalhe/`,  {...search, ...filter}); 

    return data;
  }

  static async getDetalheGraficoSensoLine(search) {
    let filter = await this.getFilter()    
    const { data } = await api.post(`/ho/dashboard/grafico/detalhado/senso`, {...search, ...filter}); 

    return data;
  }

  static async getAcoesConcluidasCoergo() {
    const filter = await this.getFilter();
    const { data } = await api.post(`/dashboard/coergo/acoesconcluidas`, filter);
    
    return data;
  }

  static async getDetalheHomeGraficos(search) {
    const filter = await this.getFilter();
    const { data } = await api.post(`/ho/dashboard/detalhe/grafico/`, {...search, ...filter});
    
    return data;
  }
}  