import NotificationActions from '../ducks/notifier';
import { UsuarioTypes } from '../ducks/usuario';

const notifierMiddleware = ({ dispatch }) => next => (action) => {
  if (Object.prototype.hasOwnProperty.call(action, 'notifier')) {
    const { message, options } = action.notifier;
    dispatch(NotificationActions.notify(message, options));
  }

  return next(action);
};

const signoutMiddleware = ({ dispatch }) => next => (action) => {
  if (action.type === UsuarioTypes.SIGNOUT) {
    //dispatch(ChatActions.disconnect());
  }

  return next(action);
};



export default [
  notifierMiddleware,
  signoutMiddleware,
];
