import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';
import "./video-questionario.css"

class VideoApp extends Component {
    player = {}
    hideControlsPlay = ['seekbar']
    state = {
        video: {
            // src: "https://sistema.alicrimsaude.com.br/videos/treinamento-em-ergonomia.mp4",
            src: process.env.REACT_APP_URL_SISTEMA + "/treinamento/treinamento-em-ergonomia-second.mp4",
            poster: process.env.REACT_APP_URL_SISTEMA + "/treinamento/img/treinamento.png"
        }
    }

    onPlayerReady(player){
        // // console.log("Player is ready: ", player);
        this.player = player;
    }

    onVideoPlay(duration){
        // // console.log("Video played at: ", duration);
    }

    onVideoPause(duration){
        // // console.log("Video paused at: ", duration);
    }

    onVideoTimeUpdate(duration){
        // // console.log("Time updated: ", duration);
    }

    onVideoSeeking(duration){
        // // console.log("Video seeking: ", duration);
    }

    onVideoSeeked(from, to){
        // // console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd(){
        // console.log("Video ended");
    }

    render() {
      const {
        onVideoEnd
      } = this.props;
        return (
            <div className='player-wrapper'>
                <VideoPlayer
                    // hideControls = {this.hideControlsPlay}
                    className = 'react-player'
                    controls={true}
                    src={this.state.video.src}
                    poster={this.state.video.poster}
                    // poster={this.state.video.poster}
                    // onReady={this.onPlayerReady.bind(this)}
                    // onPlay={this.onVideoPlay.bind(this)}
                    // onPause={this.onVideoPause.bind(this)}
                    // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    // onSeeking={this.onVideoSeeking.bind(this)}
                    // onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={onVideoEnd.bind(this)}
                />
            </div>
        );
    }
}
export default VideoApp;
// import React, { Component } from 'react';
// import ReactPlayer from 'react-player';
// import "./video-questionario.css"

// const ResponsivePlayer = () => {
// {
//   return (
//       <div className='player-wrapper'>
//         <ReactPlayer
//           className='react-player'
//           url={{src: 'https://sistema.alicrimsaude.com.br/videos/treinamento-em-ergonomia.mp4?_s=vp-1.4.3', type: 'video/mp4'}}
//           width='100%'
//           height='100%'
//         />
//       </div>
//     )
//   }
// }

// export default ResponsivePlayer;