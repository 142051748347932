import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  Grid,
  Typography
}from '@material-ui/core';

import color from '../../styles/theme'
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from '../../services/api';
import { Material } from './styles';
import NotificationActions from '../../store/ducks/notifier';
import Home from './components/home';
import '../../assets/css/camera.css';
import './alterUser.css'

class Questionario extends Component {
  state = {
    apiKey: '',
    img: '',
    homeState: {
      nome: '',
      email: '',
      matricula: '',
      cargo: null,
      unidade: null,
      ghe:null,
      turno:null,
      id: null,
      _id:"",
      dataNasa:{
        nasa: {
          fase1:null,
          fase2:null
        }
      }
    },
    loading: false,
    updateLista: false,
    send: false,
    user: null
  }

  loadUser = async() => {
		const { match } = this.props;
		this.setState({loadingImg: true})
		try{
			// const {data} = await apiS3.get(`/imagem/alicrim/${selected.id}`)
			// var binary = '';
			// var bytes = new Uint8Array( data );
			// var len = bytes.byteLength;
			// for (var i = 0; i < len; i++) {
			// 	binary += String.fromCharCode( bytes[ i ] );
			// }
			// const base = window.btoa( binary );
      const user = await api.post(`/usercliente/login/${match.params.login}`)
      // // console.log('user', user)
			this.setState({user: user.data})
			this.setState({loadingImg: false})
		}catch(err){
			console.log(err)
		}
	}

  async componentWillMount() {
    await this.loadUser();
  }

  componentDidUpdate(prevProps, prevState) {
    const { setStatus, status} = this.props;

    if (status && status.updateLista) {
      setStatus({ updateLista: !status.updateLista });
      this.setState({send: true})
    }
  }

  nextHome = (value) => {
    const {setFieldValue, handleSubmit} = this.props;
    const {
      user
    } = this.state;
    // // console.log('value',value)
    this.setState({value})
    setFieldValue('newPassword', value.newPassword);
    setFieldValue('newPasswordAgain', value.newPasswordAgain);
    setFieldValue('usuario', user);
    handleSubmit();
  }

  render() {
      // const {
      //   classes
        
      // } = this.props;
      const {
        user, 
        send
      } = this.state;
    return (
      
      <Grid className={!send ? 'container-new' : 'containerinit-new'}>
          { !send ? 
          <div className="container-total-new">
            <Home style={{ display : 'none'}} user={user} next={value => this.nextHome(value)} />
          </div>
            :
            <Grid style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: color.palette.success.main, color: "#fff"}}>
              <CheckCircleIcon fontSize="large" />
              <Typography>{"Obrigado pelo seu tempo!"}</Typography>
            </Grid>
          }

         

      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({  
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
  withFormik({
    displayName: 'Questionario',
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      newPasswordAgain: null,
      newPassword: null,
      usuario: null
    }),
    handleSubmit: async (values, { props, setSubmitting, resetForm, setStatus }) => {
     
      const form = {
        ...values
      }
      try {
        // // console.log('form', form)
        let newUser = form.usuario;
        newUser.senha = form.newPassword;
        // // console.log('usuarionovosenha', newUser)
        let response = await api.post('/usercliente/save', {...newUser});
        // // console.log('response', response)
        if(response.data.error) {
          props.notify(`Houve um problema ao salvar, ${response.data.message}`, { variant: 'error' });
          return;
        }

        await setStatus({ updateLista: true });        
        resetForm();
      } catch (err) {
        props.notify(`Houve um problema ao salvar, ${err}`, { variant: 'error' });
        // console.log(err);
      }
    },
  }),
)(Questionario);