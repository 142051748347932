/* eslint-disable quote-props */
export const lb_visaoGeralEn = {
  'box.postoComRisco': 'Stations with risk',  
  'box.postoSemRisco': 'Stations without risk',
  'box.conforme': 'Compliant',
  'box.naoConforme': 'Non-compliant ',
  'box.plano': 'Action plan',
  'box.acao': 'Committee Actions',
  'box.absenteismos': 'Absenteeism',
  'box.afastamentos': 'Work Leave',
  'box.diasPerdidos': 'Lost Days',
  'box.blitz': 'Ergonomic Blitz',
  'box.blitzCorretivas': 'Corrective Actions',
  'box.blitzPreventivas': 'Preventive Actions',
  'box.ginasticaLaboral': 'Labor Gymnastics Accession',
  'box.musculoEsqueleticas': 'Musculoskeletal Discomfort',
  'box.cargaMental': 'Task Load Index',
  'boxAcoes.acoes': 'Actions',  
  'boxAcoes.naoProgramadas': 'Unscheduled',
  'boxAcoes.emProgresso': 'In Progress',
  'boxAcoes.concluidas': 'Completed',
  'boxAcoes.atrasadas': 'Overdue',
  'grafico.riscos': 'Ergonomic Risks',
  'grafico.compliance': 'Compliance',
  'grafico.musculoEsqueleticas': 'Musculoskeletal Discomfort',
  'grafico.cargaMental': 'Task Load Index',
  'graficoDetalhe.musculoEsqueleticos': 'Musculoskeletal',
  'graficoDetalhe.musculoEsqueleticosDias': 'Musculoskeletal - Lost Days',
  'graficoDetalhe.psicossociais': 'Psychosocial/Cognitives',
  'graficoDetalhe.psicossociaisDias': 'Psychosocial/Cognitives - Lost Days',
  'graficoDetalhe.diagramaCorlett': 'Musculoskeletal Discomfort',
  'graficoDetalhe.frequencia': 'Frequency',
  'graficoDetalhe.adesao': 'Accession',
  'graficoDetalhe.blitzPreventivas': 'Preventive Blitz',
  'graficoDetalhe.blitzCorretivas': 'Corrective Blitz',
  'boxDetalhe.pouca': '(0 - 19.9) Mild',
  'boxDetalhe.alguma': '(20 - 39.9) Discomforting',
  'boxDetalhe.moderada': '(40 - 59.9) Moderate',
  'boxDetalhe.bastante': '(60 - 79.9) Severe',
  'boxDetalhe.intoleravel': '(80 - 100) Worst ',
  'notify.baixando': 'Downloading',
  'notify.erroDownload': 'It was not possible to download',
  'graficoDetalhe.escala': 'Rating Scale'
}

export const lb_planoDeAcaoEn = {
  'boxAcoes.acoes': 'Actions',  
  'boxAcoes.naoProgramadas': 'Unscheduled',
  'boxAcoes.emProgresso': 'In Progress',
  'boxAcoes.concluidas': 'Completed',
  'boxAcoes.atrasadas': 'Overdue',
  'boxAcoes.alterarStatus': 'Change the status',
  'grafico.acoes': 'Completed Actions by period',
  'box.acoes.nenhuma': 'Action Not Found',
  'graficoDetalhe.acoes': 'Completed Actions',
  'tabela.programada': 'Schedule',
  'notify.erroBusca': 'An error has occurred to search all the actions',
  'notify.erroAcoes': 'An error has occurred to search the schedule actions by period',
  'notify.acaoNaoEncontrada': 'Action Not Found',
  'notify.erroAcao': 'An error has occurred to search the actions',
  'tabela.todas': 'Action Plan',
  'tabela.emProgresso': 'Actions in progress',
  'tabela.novo': 'Actions not scheduled ',
  'tabela.atrasado': 'Delayed Actions'
}

export const lb_coergoEn = {
  'boxAcoes.acoes': 'Actions',  
  'boxAcoes.naoProgramadas': 'Unscheduled',
  'boxAcoes.emProgresso': 'In Progress',
  'boxAcoes.concluidas': 'Completed',
  'boxAcoes.atrasadas': 'Overdue',
  'boxAcoes.programada': 'Schedule',
  'boxAcoes.gerenciarAcoes': 'Update Actions',
  'box.acoes.nenhuma': 'Action Not Found',
  'tabela.titulo': 'Notices',
  'atas.button': 'Minutes',
  'grafico.acoes': 'Completed Actions by period',
  'modal.detalheEquipe.titulo': 'Detail',
  'modal.detalheEquipe.nome': 'Name',
  'modal.detalheEquipe.cargo': 'Post',
  'modal.detalheEquipe.email': 'Email',
  'modal.detalheAtas.titulo': 'Minutes List',
  'modal.detalheAtas.data': 'Date',
  'modal.detalheAtas.nome': 'Nome',
  'modal.detalheAtas.descricao': 'Discription',
  'modal.detalheAtas.download': 'Download',
  'graficoDetalhe.acoes': 'Completed Actions',
  'notify.buscaAcoes': 'An error has occurred to search all the actions',
  'notify.buscaPeriodo': 'An error has occurred to search the actions by period',
  'notify.nenhumaAcao': 'Action Not Found',
  'notify.erroBusca': 'An error has occurred to search the actions',
  'notify.baixando': 'Downloading',
  'notify.erroDownload': 'It was not possible to download',
  'tabela.todas': 'Committee Actions',
  'tabela.emProgresso': 'Actions in progress',
  'tabela.novo': 'Actions not scheduled',
  'tabela.atrasado': 'Delayed Actions'
}

export const lb_homeOfficeEn = {
  'box.questPreenchidos': 'Filled-in Questionnaires',  
  'box.questValidados': 'Validated Questionnaires',  
  'box.mobiliarios': 'Furnitures',
  'box.equipamentos': 'Equipments',
  'box.ambientais': 'Environmental Factors',
  'box.organizacionais': 'Organizational Factors',
  'box.compliance': 'Compliance',
  'box.absenteismos: ': 'Absenteeism',
  //'box.musculoEsqueleticas': 'Musculoskeletal Disorders (Corlett)',
  'box.cargaMental': 'Task Load Index',
  'grafico.visaoGeral': 'Overview',
  'grafico.compliance': 'Compliance',
  'grafico.corlett': 'Musculoskeletal Discomfort',
  'grafico.cargaMental': 'Task Load Index',
  'boxDetalhe.pouca': '(0 - 19.9) Mild',
  'boxDetalhe.alguma': '(20 - 39.9) Discomforting',
  'boxDetalhe.moderada': '(40 - 59.9) Moderate',
  'boxDetalhe.bastante': '(60 - 79.9) Severe',
  'boxDetalhe.intoleravel': '(80 - 100) Worst',
  'boxDetalhe.poucaOnly': 'Pouca',
  'boxDetalhe.algumaOnly': 'Alguma',
  'boxDetalhe.moderadaOnly': 'Moderada',
  'boxDetalhe.bastanteOnly': 'Bastante',
  'boxDetalhe.intoleravelOnly': 'Intolerável',
  'tooltip.corlett': 'Collaborators',
  //'tooltip.boxConforme': 'Compliant',
  //'tooltip.boxNaoConforme': 'Non-Compliant ',
  'tooltip.satisfatorio': 'Satisfactory',
  'tooltip.naoSatisfatorio': 'Unsatisfactory',
  'graficoDetalhe.escala': 'Rating Scale'
}

export const lb_documentosEn = {
  'modal.titulo': 'Documents',
  'modal.nenhum': 'File Not Found...',
  'notify.erroLista': 'It Was Not Possible to Search The Documents List',
  'notify.baixando': 'Downloading',
  'notify.erroDownload': 'It Was Not Possible to Download'
}

export const lb_tabelasEn = {
  'cargo': 'Post',
  'cargos': 'Posts',
  'data': 'Date',
  'localTrabalho': 'Workplace',
  'locaisTrabalho': 'Workplaces',
  'setor': 'Sector',
  'turno': 'Work Shift',
  'colaboradores': 'Collaborators',
  'observacao': 'Note',
  'gerg': 'GERG',
  'qtdColaboradores': 'Collaborators Quantity',
  'cargaMental': 'Mental Load',
  'risco': 'Risk',
  'recomendacao': 'Recommendation',
  'acao': 'Action',
  'programacao' : 'Programation',
  'conclusao' : 'Conclusion',
  'riscoInicial': 'Risco Initial',
  'condicao': 'Anti-Ergonomic Condition',
  'atividadeAvaliada': 'Evaluated Activity',
  'codigosNr17': 'NR 17',
  'codigosESocial': 'E-Social Code',
  'resultado': 'Results',
  'presenca': 'Presence',
  'ausencia': 'Absences',
  'falta': 'Miss',
  'consultor': 'Consultant',
  'quantidade': 'Quantity',
  'codigoCid': 'CID',
  'horasPerdidas': 'Lost Hours',
  'diasPerdidos': 'Lost Days',
  'resultado.verde': 'Tolerable',
  'resultado.amarelo': 'Moderate',
  'resultado.vermelho': 'High',
  'resultado.roxo': 'Very High',
  'dataProgramada': 'Schedule Date',
  'dataConclusao': 'Completion Date',
  'responsavel': 'Responsable',
  'prioridade': 'Priority',
  'riscoAtual': 'Current Risk',
  'riscoEsperado': 'Expected Risc',
  'matricula': 'Registration',
  'status': 'Status',
  'feedback': 'Feedback',
  'email': 'E-mail',
  'nome': 'Name',
  'aprovado': 'Approved',
  'revisado': 'Reviewed',
  'unidade': 'Unit',
  'codigo': 'Code',
  'dataFormat': 'yyyy/MM/dd',
  'dataFormatMoment': 'YYYY/MM/DD',
  'atividade': 'Activity',
  'motivo': 'Reason',
  'manual': 'Methodology',
  'custo': 'Cost',
  'custoTotal': 'Total Cost'
}

export const lb_menuEn = {
  'resumo': 'MGT summary',
  'planoDeAcao': 'Action Plan',
  'coergo': 'COERGO',
  'homeOffice': 'Home Office',
  'documentos': 'Documents',
  'sair': 'Exit',
  'filtroPeriodo.ano': 'Year',
  'filtroPeriodo.semestre': 'Semester',
  'filtroPeriodo.trimestre': 'Trimester',
  'filtroPeriodo.mes': 'Month',
  'filtroPeriodo.periodo': 'Period',
  'tooltip.filtros': 'Filters',
  'tooltip.abrirChamado': 'Open a Call',
  'modal.title': 'Filter by period',
  'header.coergo': 'COERGO - Ergonomic Committee',
  'label.nome': 'Full Name',
  'label.email': 'E-mail',
  'label.assunto': 'Subjetc',
  'label.telefone': 'Phone Number',
  'label.mensagem': 'Message',
  'button.enviar': 'Send',
  'notify.preencherData': 'To use this option, plese fill the data field.',
  'notify.suporte': 'Support Call Successfully Done!',
  'notify.erroSuporte': 'There was an error to call the support',
  'label.cliente': 'Unit',
  'treinamento': 'Training',
  'tooltip.setores': 'Sectors',
  'tooltip.cargos': 'Posts',
  'tooltip.local': 'Workplace',
  'tooltip.dataInicial': 'Initial date',
  'tooltip.dataFinal': 'Final date',
  'tooltip.limpar': 'Clear filter',
  'tooltip.filtrar': 'Filter',
  'dataFormat': 'yyyy/MM/dd',
  'grupoCliente': 'View group dashboard?',
  'tooltip.unidade': 'Unity'
}

export const lb_questionarioEn = {
  'mark.alta': 'High',
  'mark.media': 'Medium',
  'mark.baixa': 'Low',
  'home.text1': 'Hi, Welcome to our ergonomic assessment questionnaire!',
  'home.text2': 'To continue, enter your data:',
  'label.ghe': 'GERG',
  'label.turno': 'Shift',
  'label.cargo': 'Post',
  'label.setor': 'Sector',
  'label.localTrabalho': 'Workplace',
  'label.unidade': 'Unit',
  'label.nome': 'Full Name',
  'label.email': 'E-mail',
  'label.assunto': 'Subject',
  'label.telefone': 'Phone Number',
  'label.matricula': 'Registration',
  'label.matriculacpf': 'Registration/CPF',      
  'label.age': 'Age',
  'label.nenhum': 'None',
  'label.weight': 'Weight',
  'label.height': 'Height',
  'button.continuar': 'Continue',
  'imgTitle': 'Observe the picture below:',
  'videoTitle': 'Watch the video below to continue:',
  'videoTitle1': ' Watch the 1st video below to continue:',
  'videoTitle2': ' Watch the 2nd video below to continue:',
  'home.title1': 'Based on your home office work, answer:',
  'home.title2': "Now, Let's talk about your health. As a result of performing your daily work, do you feel discomfort in any of the regions below ?",
  'button.ajuda': 'Help',
  'home.titleImc': 'Analysis of body mass index',
  'home.title3': 'We need to make some questions about your mental load level:',
  'home.requestOne': 'Do you practice physical exercise?',
  'home.requestTwo': 'Which?',
  'home.requestThree': 'What frequency?',
  'home.frequency': '1 to 2 times a week',
  'home.frequencyTwo': '3 to 5 times a week',
  'home.frequencyThree': 'more than 5 times a week',
  'home.yes': 'Yes',
  'home.no': 'No',
  'home.frequencyLabel': 'Frequency',
  'home.activity' : 'Physical Exercise',
  //'nasa.fase1': 'Fase 1:',
  //'nasa.fase2': 'Fase 2:',
  //'home.title4': 'Agora, selecione nos pares abaixo qual o fator que representa uma maior contribuição à carga de trabalho para a sua atividade de acordo com as respostas da Fase 01.',
  'home.title5': 'Choose one of the alternatives:',
  'nasa.opcao0': 'Mental Demand',
  'nasa.opcao1': 'Physical Demand',
  'nasa.opcao2': 'Time Demand',
  'nasa.opcao3': 'Development',
  'nasa.opcao4': 'Effort',
  'home.title6': 'Thank you to answer the questionnaire.',
  'home.title7': 'We need a picture of your workplace to finish.',
  'home.title88': 'Here is a model image of a workstation:',
  'button.foto1': 'Choose picture',
  'button.foto2': 'Take a picture',
  'dropzone': 'Click to select a picture',
  'home.title8': 'Thank you for your attention !',
  'button.anterior': 'Previous',
  'button.finalizar': 'Finish',
  'modal.descricao': 'Discription:',
  'notify.alertaCam': 'It will be neccessary a picture in the end, please, allow access!',
  'notify.semCam': 'Camera not detected, it will not be possible to finish without it!',
  'notify.erroSubmit': 'Fill the fields!',
  'notify.erroQuest': 'Answers all the questionnaires!',
  'notify.avisoFoto': 'Take/Choose a picture to finish!',
  'notify.erroImg': 'Error sending picture!',
  'notify.cpf': 'CPF Invalid!',
  'notify.email': 'E-mail Invalid!',
  'home.explicaNasa': 'The assessment you are about to perform was developed by NASA, it is a technique to assess the relative importance of six factors in determining how much workload you are submitted to by completing your tasks. These six factors are defined in the table below, read them to make sure you understand what each factor means.',
  'home.tableTitle': '6 dimensions definitions - Use the relation concepts below to answer the questionnaire',
  'home.variaveis': 'Variables',
  'home.definicoes': 'Definitions',
  'home.relacao': 'Ratio',
  'home.fase1Title1': 'Now, You will be presented to a series of rating scale factor pairs.',
  'home.fase1Title2': 'To each pair, choose the factor that is most important to your experience of workload in the task you perform.',
  'home.fase2Title1': 'Now, You will see a series of rating scales. For each of the six scales, rate the task you perform by clicking on the scales location that matches to your experience.',
  'home.fase2Title2': 'Each line has two extremes that describe the scale. Consider your answers carefully when distinguishing between different task conditions and consider each scale individually',  
  'notify.erroLink': 'Incompleted Link!',
  'home.termoClick': 'By clicking on Continue, you confirm that you agree to the terms of use ',
  'home.click': 'Click to view',
  'home.termo1': 'Dear Collaborator',
  'home.termo2': 'This form aims to guide you on the basic health/ergonomic measures to be taken so that you work in a healthy environment.',
  'home.termo3': 'The questions to be asked will map the conditions of your Home Office workstation, as well as your health, with issues related to postural discomfort and your level of mental load in order to provide you with the necessary accessories for a helthy environment ',
  'home.termo4': 'At the end of the form, it will be necessary to upload a photo of your workstation for our team of specialists to carry out the ergonomic evaluation and propose the necessary improvements to you.',
  'home.termo5': 'There is a confidentiality agreement and information secrecy, guaranteeing the non-disclosure of your data to others, except the administrators/managers of the contract.',
  'home.termo6': 'We wish you a great training!',
  'home.termo7': 'Acceptance of Terms',
  'home.termo8': 'I declare that I have agreed to be interviewed and/or to participate in the Ergonomic Study of my Home Office workstation.',
  'home.termo9': 'I affirm that I agreed to participate by my own decision, without receiving any financial incentive or having any bonus and with the exclusive purpose of collaborating for the success of the Ergonomic Study.',
  'home.termo10': 'I was also clarified that the uses of the information I offer are submitted to the necessary Ergonomic Evaluations.',
  'home.termo11': 'The access and analysis of the collected data will be done only by Alicrim Ergonomists and Ergonomists and Contract Managers of my company.',
  'label.digite': 'Type to search'
}

export const lb_modalEn = {
  'modalPlano.opcao1': 'Action plan in progress',
  'modalPlano.opcao2': 'Unscheduled action plan',
  'modalPlano.opcao3': 'Completed Action Plan',
  'modalPlano.buttonTitle': 'Action plan in progress',
  'modalPlano.recomendacao': 'Recommendation',
  'modalPlano.buttonAtual': 'Update status of selected plans',
  'modalPlano.novoStatus': 'New Status',
  'modalPlano.buttonSalvar': 'Save',
  'modalPlano.atualizar': 'Update',
  'modalPlano.coergo': 'COERGO',
  'modalPesquisa.imgPosto': 'Workplace picture',
  'modalPesquisa.pergunta': 'Question',
  'modalPesquisa.resposta': 'Answer',
  'modalPesquisa.categoria': 'Category',
  'modalPesquisa.corlett': 'Corlett',
  'modalPesquisa.regiao': 'Body Region',
  'modalPesquisa.nivel': 'Pain Level',
  'modalPesquisa.resultado': 'Result',
  'modalPesquisa.parVar': 'Variable pairs',
  'modalPesquisa.var1': 'Variable 1',
  'modalPesquisa.var2': 'Variable 2',
  'modalPesquisa.revisado': 'Reviewed by',
  'modalPesquisa.dataRev': 'Review Date',
  'modalPesquisa.obs': 'Review Notes',
  'modalPesquisa.concluido': 'Completed',
  'modalPesquisa.nConcluido': 'Not Completed',
  'modalPesquisa.dados': 'Personal Data',
  'modalPesquisa.perguntas': 'Questions',
  'modalPesquisa.nasa': 'Task Load Index',
  'modalPesquisa.revisao': 'Review',
  'modalPesquisa.itensPorPag': 'Itens by page',
  'notify.statusAtualizado': 'Status Updated Successfully',
  'notify.falhaStatus' :'Failed to update status',
  'modalPlano.filtrar': 'Filter Status',
  'modalPalno.acaoInvalida': 'Invalid Date!',
  'modalInitial': 'Choose a unit!',
  'modalPesquisa.titleCorlett': 'Musculoskeletal Discomfort',
  'modalPesquisa.titleNasa': 'Task Load Index',
  'modalPesquisa.titleCoergo': 'Committee Action Plan',
  'modalPesquisa.titleAcao': 'Action Plan',
  'modalPesquisa.compliance': 'Compliance',
  'modalPesquisa.exportar': 'Export to PDF',
  'modalPesquisa.exportar-xls': 'Export to XLS',
  'notify.dataObrigatorio': 'Date is required.',
  'notify.responsavelObrigatorio': 'Responsible is mandatory.',
  'notify.dataMenorHoje': 'Date must be greater than the current date.',
}

export const lb_treinoEn = {
  'video1.titulo': 'Módulo 1',
  'video1.subTitulo': 'Treinamento em ergonomia',
  'mostrarNavegacao': 'Show navigation',
  'esconderNavegacao': 'Hide navigation',
  'videos': 'Videos'
}