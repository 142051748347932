/* eslint-disable quote-props */                                    
export const lb_visaoGeralEs = {                                      
    'box.postoComRisco': 'Publicaciones con Riesgos',                          
    'box.postoSemRisco': 'Publicaciones sin Riesgos',                          
    'box.conforme': 'Cumple',                               
    'box.naoConforme': 'No Cumple',                            
    'box.plano': 'Plan de Acción',                                  
    'box.acao': 'Acciones del Comité',                                   
    'box.absenteismos': 'Ausentismo',                           
    'box.afastamentos': 'Ausencias',                           
    'box.diasPerdidos': 'Días perdidos',                           
    'box.blitz': 'Blitzes ergonómicos',                                  
    'box.blitzCorretivas': 'Correctivo',                       
    'box.blitzPreventivas': 'Preventivo',                      
    'box.ginasticaLaboral': 'Adherencia del trabajo gimnástico',                      
    'box.musculoEsqueleticas': 'Malestar del músculo esquelético',                   
    'box.cargaMental': 'Intensidad de la carga mental',                           
    'boxAcoes.acoes': 'Acciones',                            
    'boxAcoes.naoProgramadas': 'No programado',                   
    'boxAcoes.emProgresso': 'En curso',                      
    'boxAcoes.concluidas': 'Completado',                       
    'boxAcoes.atrasadas': 'Retrasado',                        
    'grafico.riscos': 'Riesgos ergonómicos',                            
    'grafico.compliance': 'Conformidad',                        
    'grafico.musculoEsqueleticas': 'Malestar del músculo esquelético',               
    'grafico.cargaMental': 'Intensidad de la carga mental',                       
    'graficoDetalhe.musculoEsqueleticos': 'Músculo esquelético',        
    'graficoDetalhe.musculoEsqueleticosDias': 'Músculo esquelético: días perdidos',    
    'graficoDetalhe.psicossociais': 'Psicosocial / cognitivo',              
    'graficoDetalhe.psicossociaisDias': 'Psicosocial / cognitivo: días perdidos',          
    'graficoDetalhe.diagramaCorlett': 'Malestar del músculo esquelético',            
    'graficoDetalhe.frequencia': 'Frecuencia',                 
    'graficoDetalhe.adesao': 'Adherencia',                     
    'graficoDetalhe.blitzPreventivas': 'Blitz preventivo',           
    'graficoDetalhe.blitzCorretivas': 'Blitz correctivo',            
    'boxDetalhe.pouca': '(0 - 19.9) Poco',               
    'boxDetalhe.alguma': '(20 - 39.9) Algo',             
    'boxDetalhe.moderada': '(40 - 59.9) Moderado',           
    'boxDetalhe.bastante': '(60 - 79.9) Bastante',           
    'boxDetalhe.intoleravel': '(80 - 100) Intolerable',         
    'notify.baixando': 'Descargando',                           
    'notify.erroDownload': 'No se pudo exportar el documento.',                       
    'graficoDetalhe.escala': 'Escala de calificación'                      
}                                                                   

export const lb_planoDeAcaoEs = {                      
    'boxAcoes.acoes': 'Acciones',              
    'boxAcoes.naoProgramadas': ' No programadas',     
    'boxAcoes.emProgresso': ' En curso',        
    'boxAcoes.concluidas': ' Completadas',         
    'boxAcoes.atrasadas': ' Demoradas',          
    'boxAcoes.alterarStatus': ' Cambiar estado',      
    'grafico.acoes': ' Acciones completadas por período',               
    'box.acoes.nenhuma': ' No se encontraron acciones...',        
    'graficoDetalhe.acoes': ' Acciones completadas',        
    'tabela.programada': ' Programadas',           
    'notify.erroBusca': ' Se produjo un error al obtener todas las acciones',           
    'notify.erroAcoes': ' Se produjo un error al obtener las acciones programadas por período. ',           
    'notify.acaoNaoEncontrada': ' No se encontró ninguna acción',   
    'notify.erroAcao': ' Se produjo un error al obtener las acciones',            
    'tabela.todas': ' Todas las acciones',               
    'tabela.emProgresso': ' Acciones en curso',         
    'tabela.novo': ' Acciones no programadas',                
    'tabela.atrasado': ' Acciones retrasadas'             
}                                                    
export const lb_coergoEs = {                                    
    'boxAcoes.acoes': 'Acciones',
    'boxAcoes.naoProgramadas': ' No programadas',     
    'boxAcoes.emProgresso': ' En curso',                        
    'boxAcoes.concluidas': ' Completadas',                   
    'boxAcoes.atrasadas': ' Demoradas',                    
    'boxAcoes.programada': ' Programadas',                   
    'boxAcoes.gerenciarAcoes': ' Administrar acciones',               
    'box.acoes.nenhuma': ' No se encontraron acciones...',                  
    'tabela.titulo': ' Avisos',                         
    'atas.button': ' Actas',                           
    'grafico.acoes': ' Acciones completadas por período',                         
    'modal.detalheEquipe.titulo': ' Detalle',            
    'modal.detalheEquipe.nome': ' Nombre',             
    'modal.detalheEquipe.cargo': ' Posición',            
    'modal.detalheEquipe.email': ' Correo electrónico',            
    'modal.detalheAtas.titulo': ' Lista de minutos',             
    'modal.detalheAtas.data': ' Fecha',               
    'modal.detalheAtas.nome': ' Nombre',               
    'modal.detalheAtas.descricao': ' Descripción',          
    'modal.detalheAtas.download': ' Descarga',           
    'graficoDetalhe.acoes': ' Acciones completadas',                 
    'notify.buscaAcoes': ' An error al obtener todas las acciones',                    
    'notify.buscaPeriodo': ' Se produjo un error al obtener las acciones programadas por período.',                  
    'notify.nenhumaAcao': ' No se encontró ninguna acción',                   
    'notify.erroBusca': ' Se produjo un error al obtener las acciones',                     
    'notify.baixando': ' Descargando',                      
    'notify.erroDownload': ' No se pudo descargar',                  
    'tabela.todas': ' Acciones del comité',                         
    'tabela.emProgresso': ' Acciones en curso',                   
    'tabela.novo': ' Acciones no programadas ',                          
    'tabela.atrasado': ' Acciones retrasadas'                       
}                                                             
export const lb_homeOfficeEs = {                          
    'box.questPreenchidos': 'Cuestionarios completados',                   
    'box.questValidados': 'Cuestionarios validados',                     
    'box.mobiliarios': 'Mobiliario',                        
    'box.equipamentos': 'Equipo',                       
    'box.ambientais': 'F. Ambiental',                      
    'box.organizacionais': 'F. Organizacional',                 
    'box.compliance': 'Conformidad',                         
    'box.absenteismos: ': 'Ausentismo',                     
    'box.cargaMental': 'Intensidad de la carga mental',                        
    'grafico.visaoGeral': 'Visión general',                     
    'grafico.compliance': 'Conformidad',                    
    'grafico.corlett': 'Malestar del músculo esquelético',                       
    'grafico.cargaMental': 'Intensidad de la carga mental',                   
    'boxDetalhe.pouca': '(0 - 19.9) Poco',           
    'boxDetalhe.alguma': '(20 - 39.9) Algo',         
    'boxDetalhe.moderada': '(40 - 59.9) Moderado',       
    'boxDetalhe.bastante': '(60 - 79.9) Bastante',       
    'boxDetalhe.intoleravel': '(80 - 100) Intolerable',     
    'boxDetalhe.poucaOnly': 'Pouca',
    'boxDetalhe.algumaOnly': 'Alguma',
    'boxDetalhe.moderadaOnly': 'Moderada',
    'boxDetalhe.bastanteOnly': 'Bastante',
    'boxDetalhe.intoleravelOnly': 'Intolerável',
    'tooltip.corlett': 'Colaboradores',                       
    'tooltip.satisfatorio': 'Satisfactorio',                  
    'tooltip.naoSatisfatorio': 'No satisfactorio ',               
    'graficoDetalhe.escala': 'Escala de valoración'                  
  }                                                       
  export const lb_documentosEs = {           
    'modal.titulo': 'Documentos',            
    'modal.nenhum': 'No se encontraron archivos...',         
    'notify.erroLista': 'No se pudo recuperar la lista de documentos',        
    'notify.baixando': 'Descargando',         
    'notify.erroDownload': 'No se pudo descargar'      
  }                                        
  export const lb_tabelasEs = {        
    'custo': 'Custo',         
    'cargo': 'Cargo',                      
    'cargos': 'Cargos',                     
    'data': 'Fecha',                       
    'localTrabalho': 'Lugar de trabajo',              
    'locaisTrabalho': 'Lugares de trabajo',                       
    'setor': 'Sector',                      
    'turno': 'Turno',              
    'colaboradores': 'Empleados',                 
    'gerg': 'GERG',                           
    'qtdColaboradores': 'Qtd Observación',       
    'empregados': 'Empleados',                
    'cargaMental': 'Carga mental',                     
    'risco': 'Riesgo',              
    'recomendacao': 'Recomendación',
    'riscoInicial': 'Risco Inicial',
    'acao': 'Ación',           
    'programacao' : 'Programación',
    'conclusao' : 'Conclusión',       
    'condicao': 'Condición anti ergonómica',         
    'codigosNr17': 'NR 17',                   
    'atividadeAvaliada': 'Actividad evaluada',            
    'codigosESocial': 'Código E-Social',                 
    'resultado': 'Resultado',                  
    'presenca': 'Presencia',                  
    'ausencia': 'Ausencia',                     
    'falta': 'Ausencia',                 
    'consultor': 'Consultor',                
    'codigoCid': 'Cantidad CID',             
    'codigo': 'Código',             
    'horasPerdidas': 'Horas perdidas',              
    'diasPerdidos': 'Días perdidos',           
    'resultado.verde': 'Aceptable',         
    'resultado.amarelo': 'Moderado',        
    'resultado.vermelho': 'Alto',            
    'resultado.roxo': 'Muy alto',            
    'dataProgramada': 'Fecha programada',             
    'dataConclusao': 'Fecha de finalización',               
    'responsavel': 'Responsable',                
    'prioridade': 'Prioridad',                
    'riscoAtual': 'Riesgo actual',             
    'riscoEsperado': 'Riesgo esperado',                 
    'status': 'Status',                       
    'feedback': 'Feedback',                   
    'email': 'E-mail',                        
    'registro': 'Registro',                      
    'nome': 'Nombre',                  
    'aprovado': 'Aprobado',                  
    'revisado': 'Revisado',                   
    'unidade': 'Unidad',                    
    'dataFormat': 'dd/MM/yyyy',               
    'dataFormatMoment': 'DD/MM/YYYY'          
  }                                           
  export const lb_menuEs = {                                       
    'resumo': 'Resumen de gestión',                                        
    'planoDeAcao': 'Plan de acción',                                   
    'coergo': 'COERGO',                                          
    'homeOffice': 'Home Office',                                 
    'documentos': 'Documentos',                                    
    'sair': 'Licencia',                                          
    'filtroPeriodo.ano': 'Año',                             
    'filtroPeriodo.semestre': 'Semestre',                        
    'filtroPeriodo.trimestre': 'Trimestre',                       
    'filtroPeriodo.mes': 'Mes',                             
    'filtroPeriodo.periodo': 'Periodo',                         
    'tooltip.filtros': 'Filtros',                               
    'tooltip.abrirChamado': 'Convocatoria abierta',                         
    'modal.title': 'Filtro de periodo',                                  
    'header.coergo': 'COERGO - Comité de Ergonomía',                       
    'label.nome': 'Nombre Completo',                                   
    'label.email': 'E-mail',                                     
    'label.assunto': 'Asunto',                                
    'label.telefone': 'Teléfono',                               
    'label.mensagem': 'Mensaje',                               
    'button.enviar': 'Enviar',                                
    'notify.preencherData': 'Para utilizar esta opción rellene los campos de fecha.',                         
    'notify.suporte': '¡Llamada de soporte realizada con éxito!',                               
    'notify.erroSuporte': 'Hubo un error al realizar la llamada de soporte.',                           
    'label.cliente': 'Unidad',                                
    'treinamento': 'Capacitación',                                  
    'tooltip.setores': 'Sectores',                              
    'tooltip.cargos': 'Posiciones',                               
    'tooltip.local': 'Lugar de trabajo',                                
    'tooltip.dataInicial': 'Fecha de inicio',                          
    'tooltip.dataFinal': 'Fecha de finalización',                            
    'tooltip.limpar': 'Borrar filtro',                               
    'tooltip.filtrar': 'Filtro',                              
    'dataFormat': 'dd/MM/yyyy',                                  
    'grupoCliente': '¿Pantalla dashboard del grupo ?'          
  }                                                              
  export const lb_questionarioEs = {                     
    'mark.alta': 'Alto',                           
    'mark.media': 'Medio',                          
    'mark.baixa': 'Bajo',                          
    'home.text1': 'Hola, ¡Bienvenido al cuestionario para evaluación ergonómica!',                          
    'home.text2': 'Para continuar, ingrese sus datos:',    
    'label.ghe': 'GERG',
    'label.turno': 'Cambio',                      
    'label.cargo': 'Título',          
    'label.setor': 'Sector',
    'label.localTrabalho': 'Lugar de Trabajo',               
    'label.unidade': 'Unidad',                       
    'label.nome': 'Nombre Completo',                          
    'label.email': 'E-mail',                           
    'label.assunto': 'Asunto',                       
    'label.telefone': 'Teléfono',                      
    'label.matricula': 'Registro',  
    'label.matriculacpf': 'Registro/CPF',   
    'label.age': 'Edad',               
    'label.nenhum': 'Ninguno',      
    'label.weight': 'Peso',
    'label.height': 'Altura',                 
    'button.continuar': 'Continuar',                   
    'imgTitle': 'vea la imagen a continuación:', 
    'videoTitle': 'Mira el video a continuación para continuar:',              
    'videoTitle1': ' Mira el 1º video a continuación para continuar:',
    'videoTitle2': ' Mira el 2º video a continuación para continuar:',            
    'home.title1': 'Según el trabajo de su oficina en casa, responda:',                        
    'home.title2': ' ahora hablemos de su salud. Como resultado de realizar su trabajo diario, ¿Siente molestias en alguna de las regiones siguientes?',                       
    'home.titleImc': 'Análisis del índice de masa corporal',
    'home.requestOne': '¿Practicas ejercicio físico?',
    'home.requestTwo': '¿Cual?',
    'home.requestThree': '¿Qué frecuencia?',
    'home.frequency': '1 a 2 veces por semana',
    'home.frequencyTwo': '3 a 5 veces por semana',
    'home.frequencyThree': 'más de 5 veces por semana',
    'home.yes': 'Si',
    'home.no': 'No',
    'home.frequencyLabel': 'Frecuencia',
    'home.activity' : 'Ejercicio físico',
    'button.ajuda': 'Ayuda',                       
    'home.title3': 'Necesitamos hacerte algunas preguntas relacionadas con tu nivel de carga mental:',                        
    'home.title5': 'Elige una de las dos alternativas:',                        
    'nasa.opcao0': 'Requisito mental',                        
    'nasa.opcao1': 'Requisito físico',                        
    'nasa.opcao2': 'Requisito temporal',                        
    'nasa.opcao3': 'Desempeño',                        
    'nasa.opcao4': 'Esfuerzo',                        
    'home.title6': 'muchas gracias por responder el cuestionario.',                        
    'home.title7': 'Necesitamos una imagen de tu trabajo para terminar.',  
    'home.title88': 'Aquí hay una imagen modelo de una estación de trabajo:',                      
    'button.foto1': 'Elija una foto',                       
    'button.foto2': 'Tome una foto',                       
    'dropzone': 'Haga clic para seleccionar una imagen',                           
    'home.title8': '¡Gracias por su tiempo!',                        
    'button.anterior': 'Anterior',                    
    'button.finalizar': 'Finalizar',                   
    'modal.descricao': 'Descripción:',                    
    'notify.alertaCam': 'Se requerirá una foto al final, ¡Por favor',                   
    'notify.semCam': 'permite el acceso!',                      
    'notify.erroSubmit': 'CAMERA no detectada, ¡No será posible terminar sin él!',                  
    'notify.erroQuest': '¡Rellena todos los campos!',                   
    'notify.avisoFoto': '¡Responda todos los cuestionarios!',                   
    'notify.erroImg': '¡Toma / elige una foto para terminar!',
    'notify.cpf': 'CPF Inválido!',
    'notify.email': 'E-mail Inválido!',                     
    'home.explicaNasa': '¡Error al enviar la imagen!',                   
    'home.tableTitle': 'La evaluación que está a punto de realizar fue desarrollada por la NASA, es una técnica para evaluar la importancia relativa de seis factores para determinar cuánta carga de trabajo está sujeto al completar sus tareas. Estos seis factores se definen en la siguiente tabla, léalos para asegurarse de que comprende lo que significa cada factor.',                    
    'home.variaveis': 'Definiciones de las 6 dimensiones - Utilice los conceptos de relación a continuación para responder el cuestionario',                     
    'home.definicoes': 'Variables',                    
    'home.relacao': 'Definiciones',                       
    'home.fase1Title1': 'Relación',                   
    'home.fase1Title2': 'Ahora se le presentará una serie de pares de factores de escala de calificación.',                   
    'home.fase2Title1': 'Para cada par, elija el factor que sea más importante para su experiencia de la carga de trabajo en la tarea que realiza.',                   
    'home.fase2Title2': 'Ahora verá una serie de escalas de calificación. Para cada una de las seis escalas; evalúe la tarea que realiza tocando / haciendo clic en la ubicación de las escalas que coincida con su experiencia.',                   
    'notify.erroLink': '¡Link incompleta!',                    
    'home.termoClick': 'cuando haga clic en Continuar, usted confirma que está de acuerdo con los términos de uso',                   
    'home.click': 'haga clic para ver',                         
    'home.termo1': 'Estimado colaborador',                        
    'home.termo2': 'Esta capacitación / formulario tiene como objetivo orientarlo sobre las medidas básicas de salud / ergonomía que debe tomar para que trabaje en un entorno saludable.',                        
    'home.termo3': 'Las preguntas que se harán, mapeará las condiciones de su estación de trabajo de Home Office, así como su salud; con preguntas relacionadas con el malestar postural y su nivel de carga mental con el fin de brindarle los accesorios necesarios para que su entorno sea saludable.',                        
    'home.termo4': 'Al finalizar el formulario será necesario subir una foto de su puesto de trabajo para que nuestro equipo de especialistas realice la evaluación ergonómica y le proponga las mejoras necesarias.',                        
    'home.termo5': 'Existe un término de confidencialidad y secreto de la información, garantizar la no divulgación de sus datos a terceros, excepto administradores / gerentes de contratos.',                        
    'home.termo6': '¡Te deseamos una gran formación!',                        
    'home.termo7': 'Plazo de aceptación',                        
    'home.termo8': 'declaro, a través de este término; que acepté ser entrevistado y / o participar en el estudio ergonómico de mi trabajo Home Office.',           
    'home.termo9': 'afirmo que acepté participar por mi propia voluntad, sin recibir ningún incentivo económico ni tener ninguna carga y con el único propósito de colaborar para el éxito del estudio ergonómico.',                        
    'home.termo10': 'también se me aclaró que los usos de la información ofrecida por mí están sujetos a las evaluaciones ergonómicas necesarias.',                       
    'home.termo11': 'El acceso y análisis de los datos recogidos será realizado únicamente por Ergonomistas y Ergonomistas de Alicrim y Responsables Contractuales de mi empresa.'                        
  }													  
  export const lb_modalEs = {                              
    'modalPlano.opcao1': 'Plan de acción en curso',                     
    'modalPlano.opcao2': 'nuevo plan de acción',                     
    'modalPlano.opcao3': 'plan de acción completado',                     
    'modalPlano.buttonTitle': 'plan de acción',                
    'modalPlano.recomendacao': 'recomendación',               
    'modalPlano.buttonAtual': 'estado de actualización de los planes seleccionados',                
    'modalPlano.novoStatus': 'nuevo Status',          
    'modalPlano.buttonSalvar': 'guardar',               
    'modalPlano.atualizar': 'actualizar',                  
    'modalPlano.coergo': 'COERGO',                       
    'modalPesquisa.imgPosto': 'imagen del trabajo',                
    'modalPesquisa.pergunta': 'pregunta',               
    'modalPesquisa.resposta': 'respuesta',               
    'modalPesquisa.categoria': 'categoría',              
    'modalPesquisa.corlett': 'Corlett',                  
    'modalPesquisa.regiao': 'región del cuerpo ',                 
    'modalPesquisa.nivel': 'Nivel de dolor',                  
    'modalPesquisa.resultado': 'Resultado',              
    'modalPesquisa.parVar': 'Pares de variables',                 
    'modalPesquisa.var1': 'Variable 1',                 
    'modalPesquisa.var2': 'Variable 2',                 
    'modalPesquisa.revisado': 'Revisado por',               
    'modalPesquisa.dataRev': 'Fecha de revisión',                
    'modalPesquisa.obs': 'Notas de revisión',                    
    'modalPesquisa.concluido': 'Completado',              
    'modalPesquisa.nConcluido': 'No completado',             
    'modalPesquisa.dados': 'Datos personales',                  
    'modalPesquisa.perguntas': 'Preguntas',              
    'modalPesquisa.nasa': 'NASA/TLX',                    
    'modalPesquisa.revisao': 'Revisión',                
    'modalPesquisa.itensPorPag': 'Elementos por página',            
    'notify.statusAtualizado': 'Status actualizado correctamente.',       
    'notify.falhaStatus' :'No se pudo actualizar status.',           
    'modalPlano.filtrar': 'Filtro  status...',         
    'modalPalno.acaoInvalida': '¡Fecha no válida!',              
    'modalInitial': '¡Seleccione un unidad!',                         
    'modalPesquisa.titleCorlett': 'Malestar musculoesquelético',           
    'modalPesquisa.titleNasa': 'Intensidad de carga mental',      
    'modalPesquisa.titleCoergo': 'Plan de acción del comité',   
    'modalPesquisa.titleAcao': 'Plan de acción',     
    'modalPesquisa.compliance': 'Conformidad',            
    'modalPesquisa.exportar': 'Exportar a PDF',       
    'modalPesquisa.exportar-xls': 'Exportar a XLS',
    'notify.dataObrigatorio': 'Se requiere fecha.',
    'notify.responsavelObrigatorio': 'Responsable es obligatorio.', 
    'notify.dataMenorHoje': 'La fecha debe ser mayor que la fecha actual.',    
  }                                                      
  export const lb_treinoEs = {          
    'video1.titulo': 'Módulo 1',    
    'video1.subTitulo': 'Entrenamiento en ergonomía',   
    'mostrarNavegacao': 'Mostrar navegación',   
    'esconderNavegacao': 'Ocultar navegación',  
    'videos': 'Videos'              
  }                                   
              