export const Material = theme => ({
  container: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    padding: '0vw 3vw'
  },
  containerinit: {
    height: '100%',
    width: '100%' 
  },
  button: {
   marginRight:'20px',
    padding: '5px',
    color: '#fff'
  },
  homeButton: {
    margin: "10px"
  },
  table: {
    margin: '10px 0px', 
    padding: '10px', 
    //border: '1px solid #555',
    backgroundColor: "#d6d6d6" ,
    borderRadius: 5
  },
  tableRow: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  NENHUMA: {
    backgroundColor: '#42f46e',
    width: '20%',
  },
  LEVE: {
    backgroundColor: '#f4d341',
    width: '20%'
  },
  MODERADO: {
    backgroundColor: '#f57c00',
    width: '20%'
  },
  FORTE: {
    backgroundColor: '#ff4621',
    width: '20%',
    color: '#fff'
  },
  EXTREMO: {
    backgroundColor: '#6d4c41',
    width: '20%',
    color: '#fff'
  },
  sensoSelected: {
    border: '3px solid black'
  },
  toolTip: {
    color: 'yellow'
  },
  titleDialog: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },  
})