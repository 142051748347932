import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
// import * as Yup from 'yup';
import eua from './../../assets/images/eua-flag.png';
import esa from './../../assets/images/es-flag.png';
import brazil from './../../assets/images/brazil-flag.png';
import Video from "../../components/VideoQuestionario";
import {
  // TextField,
  Button,
  CircularProgress,
  Grid,
  Typography,
  // InputAdornment,
  // Select,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Stepper,
  Step,
  StepLabel,
  Slider,
  Tooltip,
  Modal,
  TableContainer
}from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import color from '../../styles/theme'
import api from '../../services/api';
// import axios from 'axios';
import { Material } from './styles';
// import pasted from '../../assets/images/pasted.png'; 
// import pastedEn from '../../assets/images/pastedEn.png'; 
import CheckIcon from '@material-ui/icons/Check';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationActions from '../../store/ducks/notifier';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import MagicDropzone from 'react-magic-dropzone';
// import CameraComponent from '../../components/Camera';
import Home from './components/home';
import corlett from '../../assets/images/ajuda-corlett.png';
import corlettEn from '../../assets/images/ajuda-corlett-EN.jpg';
import AppBar from '@material-ui/core/AppBar';
import ToolbarDialog from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import '../../assets/css/camera.css';
import { lb_questionario } from '../../libs/labels';
import { lb_questionarioEn } from '../../libs/labelsEn';
import { lb_questionarioEs } from '../../libs/labelsEs';
// import imgExample from '../../assets/images/img-example.jpg';
// import Zoom from 'react-img-zoom'
import './questionario.css'
// import {
//   isMobile
// } from "react-device-detect";

const skipValidation = false;
const markBr = [
  {
    value: 0,
    label: 'Baixa',
  },
  {
    value: 10,
    label: 'Média',
  },
  {
    value: 20,
    label: 'Alta',
  },
];
const markInvertidoBr = [
  {
    value: 0,
    label: 'Alta',
  },
  {
    value: 10,
    label: 'média',
  },
  {
    value: 20,
    label: 'Baixa',
  },
];
const markEn = [
  {
    value: 0,
    label: 'Low',
  },
  {
    value: 10,
    label: 'Medium',
  },
  {
    value: 20,
    label: 'High',
  },
];
const markEs = [
  {
    value: 0,
    label: 'Low',
  },
  {
    value: 10,
    label: 'Medium',
  },
  {
    value: 20,
    label: 'High',
  },
];
const markInvertidoEn = [
  {
    value: 0,
    label: 'High',
  },
  {
    value: 10,
    label: 'Medium',
  },
  {
    value: 20,
    label: 'Low',
  },
];
const markInvertidoEs = [
  {
    value: 0,
    label: 'High',
  },
  {
    value: 10,
    label: 'Medium',
  },
  {
    value: 20,
    label: 'Low',
  },
];
const tooltipMentalBr = 'Está relacionado à alta exigência de atenção, concentração, percepção e memória'
const tooltipFisicaBr = 'Está relacionado à alta exigência de performance física como força e resistência'
const tooltipTemporalBr = 'Está relacionado à elaboração e/ou execução das atividades a curto prazo'
const tooltipDesempenhoBr = 'Está relacionado ao rendimento pessoal e a qualidade do trabalho desenvolvido'
const tooltipEsforcoBr = 'Está relacionado ao esforço exigido para executar sua atividade'
const tooltipFrustacaoBr = 'Está relacionado ao reconhecimento intrínseco e externo do trabalho realizado'

const tooltipMentalEn = 'It is related to the high demand for attention, concentration, perception and memory'
const tooltipFisicaEn = 'It is related to the high demand for physical performance such as strength and endurance'
const tooltipTemporalEn = 'It is related to the preparation and / or execution of short-term activities'
const tooltipDesempenhoEn = 'It is related to personal performance and the quality of the work developed'
const tooltipEsforcoEn = 'It is related to the effort required to perform your activity'
const tooltipFrustacaoEn = 'It is related to the intrinsic and extrinsic recognition of the work done'

const tooltipMentalEs = 'Se relaciona con la alta demanda de atención, concentración, percepción y memoria.'       
const tooltipFisicaEs = 'Está relacionado con la alta demanda de rendimiento físico como fuerza y resistencia.'       
const tooltipTemporalEs = 'Está relacionado con la preparación y / o ejecución de actividades a corto plazo.'     
const tooltipDesempenhoEs = 'Está relacionado con el desempeño personal y la calidad del trabajo desarrollado.'   
const tooltipEsforcoEs = 'Está relacionado con el esfuerzo requerido para realizar su actividad.'      
const tooltipFrustacaoEs = 'Se relaciona con el reconocimiento intrínseco y externo del trabajo realizado'    



const tableInfo = [
  {
    title: 'Exigência Mental', 
    definicao: 'Quanto a tarefa exige mentalmente e perceptualmente (ex: pensando, decidindo, calculando, lembrando, observando, procurando, etc.)? A tarefa é fácil ou difícil, simples ou complexa, exigente ou indulgente?',
    relacao: 'Está relacionado a alta exigência de atenção, concentração, percepção e memória.'
  },
  {
    title: 'Exigência Física', 
    definicao: 'Quanto a atividade exige de você fisicamente (ex: empurrando, puxando, girando, controlando, ativando etc.)? A tarefa é fácil ou difícil, devagar ou rápida, brusca ou extenuante, relaxante ou trabalhosa?',
    relacao: 'Está relacionado a alta exigência de da performance física como força e resistência.'
  },
  {
    title: 'Exigência Temporal', 
    definicao: 'Quantas ocorrências de pressão, ou quão pressionado, você se sente para realizar e alcançar  o seu nível de desempenho em cada etapa da tarefa? Quão lento e calmo ou rápido e agitado é para realizar sua tarefa?',
    relacao: 'Está relacionado a elaboração e/ou execução das atividades a curto prazo.'
  },
  {
    title: 'Desempenho', 
    definicao: 'Qual o nível de sucesso/acerto você acha que alcança para os objetivos das tarefas estabelecidas? Qual o nível de satisfação com o seu desempenho ao alcançar esses objetivos?',
    relacao: 'Está relacionado ao rendimento pessoal e a qualidade do trabalho desenvolvido.'
  },
  {
    title: 'Esforço', 
    definicao: 'Quanto você se esforça (mentalmente e físicamente) para realizar seu trabalho no seu nível de desempenho?',
    relacao: 'Está relacionado ao esforço exigido para executar sua atividade.'
  },
  {
    title: 'Frustração', 
    definicao: 'Quão inseguro, desencorajado, irritado, estressado e chateado versos seguro, gratificado, contente, relaxado ou complacente você se sente durante a tarefa?',
    relacao: 'Está relacionado ao reconhecimento intrínseco e externo do trabalho realizado.'
  }
]

const tableInfoEs = [                                  
  {                                 
    title: 'Demanda mental',              
    definicao: '¿Cuánto requiere mental y perceptivamente la tarea (por ejemplo, pensar, decidir, calcular, recordar, observar, buscar, etc.)? La tarea es fácil o difícil; simple o complejo; exigente o indulgente?',          
    relacao: 'Está relacionado con altas demandas de atención, concentración, percepción y memoria.'             
  },                                
  {                                 
    title: 'Requisito físico',              
    definicao: '¿Cuánto le requiere físicamente la actividad (por ejemplo, empujar, tirar, girar, controlar, activar, etc.)? La tarea es fácil o difícil. lento o rápido. abrupto o extenuante. relajante o laborioso?',          
    relacao: 'Está relacionado con la alta demanda de rendimiento físico como fuerza y resistencia.'             
  },                                
  {                                 
    title: 'Requisito de tiempo',              
    definicao: 'Cuántas ocurrencias de presión, o lo presionado, ¿Se siente capaz de realizar y alcanzar su nivel de desempeño en cada paso de la tarea? ¿Qué tan lento y tranquilo o rápido e inquieto es hacer su trabajo?',          
    relacao: 'Está relacionado con la preparación y / o ejecución de actividades a corto plazo.'             
  },                                
  {                                 
    title: 'Actuación',              
    definicao: '¿Qué nivel de acierto / éxito crees que alcanzas con los objetivos de las tareas establecidas? ¿Qué tan satisfecho está con su desempeño en el logro de estos objetivos?',         
    relacao: 'Está relacionado con el desempeño personal y la calidad del trabajo desarrollado.'            
  },                                
  {                                 
    title: 'Esfuerzo',             
    definicao: '¿Cuánto se esfuerza (mental y físicamente) para hacer su trabajo a su nivel de desempeño?',         
    relacao: 'Está relacionado con el esfuerzo requerido para realizar su actividad.'            
  },                                
  {                                 
    title: 'Frustración',             
    definicao: 'Qué inseguro, desanimado, irritado, Versos estresados y trastornados a salvo, satisfecho, contento, relajado o complaciente se siente durante la tarea?',         
    relacao: 'Está relacionado con el reconocimiento intrínseco y externo del trabajo realizado.'            
  }                                 
]                                  

const tableInfoEn = [
  {
    title: 'Mental Demand', 
    definicao: 'How much does the task require mentally and perceptually (eg: thinking, deciding, calculating, remembering, observing, looking, etc.)? Is the task easy or difficult, simple or complex, demanding or indulgent?',
    relacao: 'It is related to the high demand for attention, concentration, perception and memory'
  },
  {
    title: 'Physical Demand', 
    definicao: 'How much does the task require physically (eg: pushing, pulling, turning, controlling, activating, etc.)? Is the task easy or difficult, slow or fast, sudden or strenuous, relaxing or laborious?',
    relacao: 'It is related to the high demand for physical performance such as strength and endurance'
  },
  {
    title: 'Time Demand', 
    definicao: 'How many instances of pressure, or how much pressure, do you feel to perform and achieve your level of performance at each step of the task? How slow and calm or fast and agitaded is it to get your job done?',
    relacao: 'It is related to the preparation and / or execution of short-term activities'
  },
  {
    title: 'Performance', 
    definicao: 'What level of success / success do you think you achieve for the objectives of the established tasks? How satisfied are you with your performance in achieving these goals?',
    relacao: 'It is related to personal performance and the quality of the work developed'
  },
  {
    title: 'Effort', 
    definicao: 'How much do you strive (mentally and physically) to do your job at your level of performance?',
    relacao: 'It is related to the effort required to perform your activity'
  },
  {
    title: 'Frustration', 
    definicao: 'How insecure, discouraged, angry, stressed and upset instead of safe, gratified, happy, relaxed or compliant do you feel during the task?',
    relacao: 'It is related to the intrinsic and extrinsic recognition of the work done'
  }
]

class Questionario extends Component {
  state = {
    apiKey: '',
    homeState: {
      nome: '',
      email: '',
      matricula: '',
      cargo: null,
      unidade: null,
      ghe:null,
      turno:null,
      id: null,
      _id:"",
      dataNasa:{
        nasa: {
          fase1:null,
          fase2:null
        }
      }
    },
    finishedVideo: false,
    activeStep: 0,
    steps: ['', '', '', ''],
    init: false,
    regiaoCorpo: [],
    dores: [], 
    selectedSenso: [],
    showCamera: true,
    preview: null, 
    send: false,
    tirarFoto: true,
    previewDrop: null,
    extention: '.jpeg',
    modalExplicacao: false,
    explicacao: '',
    modalCorlett: false,
    loading: false,
    cameraError: false,
    lang: 'pt_BR',
    lb: {},
    reformulacao: false,
    reformulacaoData: {},
    registryDate: null
  }

  async componentWillMount() {
    const { lang } = this.state;
    const { setFieldValue, notify, apiKey,  } = this.props;

    if(lang === 'pt_BR'){
      this.setState({lb: lb_questionario})
    }else if(lang === 'en'){
      this.setState({lb: lb_questionarioEn})
    }else if(lang === 'es'){
      this.setState({lb: lb_questionarioEs})
    }
    // console.log('apiKey',apiKey)
    if(apiKey !== null && apiKey !== undefined && apiKey !== ''){
      this.setState({init: true, reformulacao: true, loading: true})
      try{

        const {data} = await api.get(`/quiz/${apiKey}`);
        setFieldValue('id', data.id);
        setFieldValue('_id', data._id);
        setFieldValue('ghe', data.ghe);
        // setFieldValue('nome', data.nome);
        setFieldValue('cargo', data.cargo);
        
        setFieldValue('localTrabalho', data.localTrabalho);
        // setFieldValue('email', data.email);
        setFieldValue('apiKey', data.apiKey);
        setFieldValue('setor', data.setor);
        // setFieldValue('homeOffice', data.homeOffice);
        setFieldValue('nasaFase1', data.nasaFase1);
        setFieldValue('nasaFase2', data.nasaFase2);
        setFieldValue('codigoUsuario', data.codigoUsuario)
        setFieldValue('registryDate', data.registryDate)
        // // console.log('data',data)
        var homeState = {
          cargo: null,
          ghe:"",
          turno:null,
          dataNasa:{
            nasa: {
              fase1:null,
              fase2:null
            }
          }};
        homeState.ghe = data.ghe;
        homeState.turno = data.turno;
        homeState.cargoSelec = data.cargo;
        homeState.localSelec = data.localTrabalho;
        homeState.setorSelec = data.setor;
        homeState.dataNasa.nasa.fase1 = data.nasaFase1;
        homeState.dataNasa.nasa.fase2 = data.nasaFase2;
        homeState.dataNasa.id = data.id
        homeState.dataNasa._id = data._id


        // console.log('datacomplete', data)
        // console.log('homeState', homeState)

        this.setState({homeState:homeState})

        // setFieldValue('senso', data.senso);
        this.setState({init: true, reformulacao: true, loading: true})
        this.fetchDados(0, data.apiKey)
        // const image = await apiS3Alicrim.get(`/homeoffice/get/${data.id}`)
        // var binary = '';
        // var bytes = new Uint8Array(image.data);
        // var len = bytes.byteLength;
        // for (var i = 0; i < len; i++) {
        //     binary += String.fromCharCode( bytes[ i ] );
        // }
        // const base64String = window.btoa(binary);
        // this.setState({previewDrop: `data:image/${data.extention === null ? this.state.extention : data.extention};base64,${image.data}`, tirarFoto: false, reformulacaoData: data, loading: false})
      }catch(err){
        if(lang === 'pt_BR'){
          notify(lb_questionario["notify.erroLink"], { variant: 'error' });
        }else if(lang === 'en'){
          notify(lb_questionarioEn["notify.erroLink"], { variant: 'error' });
        }
        console.log(err)
      }
    }else{
      this.fetchDados(1)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { setStatus, status} = this.props;
    const { lang } = this.state;

    if (status && status.updateLista) {
      setStatus({ updateLista: !status.updateLista });
      this.setState({send: true})
    }
    if(prevState.lang !== lang && lang !== ""){
      if(lang === 'pt_BR'){
        this.setState({lb: lb_questionario})
      }else if(lang === 'en'){
        this.setState({lb: lb_questionarioEn})
      }else if(lang === 'es'){
        this.setState({lb: lb_questionarioEs})
      }
      this.fetchDados(1);
    }
  }

  handleSetLinguage = (lang) => {
    this.setState({lang})
  }

  onVideoEnd = () => {
    this.setState({finishedVideo: true});
  }

  verificaCliente = async() => {
    const {match, notify} = this.props;
    const { lb } = this.state;

    try{
      await api.get(`/cliente/${match.params.apiKey}`);
      this.setState({apiKey: match.params.apiKey});
    }catch(err){
      console.log(err)
      notify(lb["notify.erroLink"], { variant: 'error' });
    }
  }

  fetchDados = async(type, apis) => {
    // const {match} = this.props;
    // const { lang } = this.state;
    this.setState({loading: true})
    try{
      if(type === 1){
//         const {data} = await api.get('/quiz/', {params:{lang}}, {
//           headers: { Authorization: match.params.apiKey }
//         })
// // console.log('data1', data)
        // this.setState({dataTeste:data}, this.setState({loading: false}))
        this.setState({loading: false})     
        this.regiaoCorpo();
        this.getDor();
      }
      else{
        // const {data} = await api.get('/quiz/', {params:{lang}}, {
        //   headers: { Authorization: apis }
        // })
        // // console.log('data', data)
        // this.setState({dataTeste:data}, this.setState({loading: false}))  
        this.setState({loading: false}) 
        this.regiaoCorpo();
        this.getDor();
      }
    }catch(err){
      console.log(err);
    }
  }

  regiaoCorpo = async() => {
    const { lang } = this.state
    try{
      const {data} = await api.get('/controller/regiaocorpo', {params:{lang}});
      this.setState({regiaoCorpo: data});
    }catch(err){
      console.log(err)
    }
  }

  getDor = async() => {
    const { lang } = this.state
    try{
      const {data} = await api.get('/controller/dor', {params:{lang}});
      this.setState({dores: data});
    }catch(err){
      console.log(err)
    }
  }

  nextHome = (homeState) => {
    const {setFieldValue, match} = this.props;
    // const { apiKey } = this.state;
    this.setState({init: true, homeState})
    setFieldValue('setor', homeState.setorSelec);
    setFieldValue('cargo', homeState.cargoSelec);
    setFieldValue('unidade', homeState.unidadeSelec);
    setFieldValue('localTrabalho', homeState.localSelec);
    setFieldValue('ghe', homeState.ghe);
    setFieldValue('turno', homeState.turno);
    setFieldValue('apiKey', match.params.apiKey);
    setFieldValue('codigoUsuario', match.params.userId)
    setFieldValue('registryDate', homeState.selectedDate)
    // setFieldValue('dataNasa', homeState.dataNasa)
  }

  getContent = (value) => {
    const { lb, lang, homeState, regiaoCorpo, dores} = this.state;
    const { classes, values} = this.props;
    // eslint-disable-next-line
    switch(value){
      case 0: 
        return(
          <Grid item sm={12} style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center'}}>
            <Grid style={{display: 'flex', flexDirection: 'row', width: '13%', justifyContent: 'center'}}>
              {/* <div className='grid-translate'> */}
                <Grid style={{display: 'flex', alignItems: 'center', marginRight: '5px'}} onClick={() => this.setState({lang: 'pt_BR'})}>
                  {/* eslint-disable-next-line*/}
                  <img src={brazil} style={{width: '20px', height: '20px', cursor: 'pointer'}}/>
                </Grid>
                <Grid style={{display: 'flex', alignItems: 'center', marginRight:'2px'}} onClick={() => this.setState({lang: 'en'})}>
                  {/* eslint-disable-next-line*/}
                  <img src={eua} style={{width: '20px', height: '20px', cursor: 'pointer'}}/>
                </Grid>
                <Grid style={{background: 'none', border: 'none', outline: 'none', display: 'flex', alignItems: 'center', marginTop: '-1px'}} onClick={() =>  this.setState({lang: 'es'})}>
                  {/* eslint-disable-next-line*/}
                  <img src={esa} style={{width: '25px', height: '25px', cursor: 'pointer'}}/>
                </Grid>
              {/*</div> */}
            </Grid>
            <Typography style={{margin: '0px 20px 10px 20px'}}>{lb["videoTitle"]}</Typography>
            {/* <img src={lang === 'pt_BR' ? pasted: lang === 'en' ? pastedEn : pasted} style={{width: "50%"}}/> */}
            <Video onVideoEnd={(value) => this.onVideoEnd()}/>
          </Grid>
        )
      // case 1: 
      //   return(
      //     <Grid item sm={12} style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center'}}>
      //       <Grid item style={{padding: '10px 20px', maxWidth: '800px'}}>
      //         <Typography>{lb["home.title1"]}</Typography>
      //         {data.homeOffice.map((item, index0) => {
      //           return(
      //             <Grid item>
      //               <Typography style={{padding: '10px', backgroundColor: '#d6d6d6', borderRadius: 5}}>{item.opcao.descricao}</Typography>
      //               {item.perguntas.map((pergunta, index1) => {
      //                 let NAresposta = false;
      //                 let naResponse = false;
      //                 let NArespostaSelected = false;
      //                 var naoUltilizo = lang === 'pt_BR'? 'Não utilizo': lang === 'en'? "I don't use" : 'No utilizo'
      //                 var notApplication = lang === 'pt_BR'? 'Não se aplica': lang === 'en'? "Does not apply" : 'No aplica'
      //                 if(pergunta.permiteNa){
      //                   NAresposta = true;
      //                 }
      //                 if(pergunta.notApplication) {
      //                   naResponse = true;
      //                 }
      //                 return(
      //                   <Grid item style={{border: '1px solid #d6d6d6', borderRadius: 5, padding: '10px', margin: '10px 0px'}}>
      //                     <Typography>{pergunta.descricao}</Typography>
      //                     <Grid>
      //                       {item.respostas.map((resposta, index2) => {
      //                         var selected = false;
      //                         try{
      //                           values.homeOffice.map(item => {
      //                             if(item.resposta === resposta.id && item.pergunta === pergunta.id){
      //                               selected=true
      //                             }else if(item.resposta === 0 && item.pergunta === pergunta.id){
      //                               NArespostaSelected = true
      //                             }else if((item.resposta === null || item.resposta === '' ) && item.pergunta === pergunta.id){
      //                               NArespostaSelected = true
      //                             }
      //                             return item
      //                           })
      //                         }catch(err){
      //                           console.log(err)
      //                         }
      //                         return (<Button variant="outlined" className={classes.homeButton} style={selected?{backgroundColor: color.palette.secondary.main, color: '#fff'}:null} onClick={() => this.changeResposta(pergunta.id, resposta.id)}>{resposta.descricao}</Button>)
      //                       })}
      //                       {NAresposta && (<Button variant="outlined" className={classes.homeButton} style={NArespostaSelected?{backgroundColor: color.palette.secondary.main, color: '#fff'}:null} onClick={() => this.changeResposta(pergunta.id, 0)}>{naoUltilizo}</Button>) }
      //                       {naResponse && (<Button variant="outlined" className={classes.homeButton} style={NArespostaSelected?{backgroundColor: color.palette.secondary.main, color: '#fff'}:null} onClick={() => this.changeResposta(pergunta.id, 0)}>{notApplication}</Button>) }
      //                     </Grid> 
      //                   </Grid>
      //                 )
      //               })}
      //             </Grid>
      //           )
      //         })}
      //       </Grid>
      //     </Grid>
      //   )
      case 1: 
      return(
        <Grid item style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center'}}>
          <Grid item style={{padding: '10px 20px', maxWidth: '800px'}}>
            <Typography>{lb["home.title2"]}</Typography>
            <Button fullWidth color="secondary" variant="contained" onClick={() => this.modalCorlett()}>
              {lb["button.ajuda"]} 
            </Button>
            {regiaoCorpo.map((item,index) => {
              return(
                  <Table className={classes.table} style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={5}>{item.label}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.tableRow}>
                        {dores.map((dor, index1) => {     
                          var selected = false;                           
                          try{
                            values.senso.map(senso => {
                              if(senso.nivelDor === dor.id && senso.regiaoCorpo === item.id){
                                selected=true
                              }
                              return senso
                            })
                          }catch(err){
                            console.log(err)
                          }
                          return(
                            <TableCell 
                                style={{padding: '0px', fontWeight: 'bold', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: window.innerWidth>840? '1vw':'3vw',  cursor: 'pointer' }}
                                padding="none"
                                onClick={() => this.changeResposta(item.id, dor.id, 'senso')} 
                                className={classes[dor.id]}
                            >
                              {selected? <CheckIcon/> : dor.label}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
              )
            })
            }
          </Grid>
        </Grid>
      )
      case 2:
      return(
        <Grid item id="spaceContainer" style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center'}}>
          <Grid item style={{padding: '10px 20px', maxWidth: '800px'}}>
            <Typography style={{marginBottom: '10px'}}><b>{lb["home.title3"]}</b></Typography>

            <Typography style={{marginBottom: '10px'}}>{lb["home.explicaNasa"]}</Typography>
            <TableContainer >
              <Table stickyHeader size="small" aria-label="a dense table" style={{tableLayout: 'auto', marginBottom: '10px'}}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>{lb["home.tableTitle"]}</TableCell>
                  </TableRow>
                  <TableRow role="checkbox" >
                    <TableCell>{lb["home.variaveis"]} </TableCell>
                    <TableCell>{lb["home.definicoes"]}</TableCell>
                    <TableCell>{lb["home.relacao"]}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lang === 'pt_BR'? 
                    tableInfo.map((item, index) => {
                      return(<TableRow role="checkbox" key={index} >
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.definicao}</TableCell>
                        <TableCell>{item.relacao}</TableCell>
                      </TableRow>)
                    })
                    : lang === 'en'? 
                    tableInfoEn.map((item, index) => {
                      return(<TableRow role="checkbox" key={index} >
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.definicao}</TableCell>
                        <TableCell>{item.relacao}</TableCell>
                      </TableRow>)
                    }) 
                    :
                    tableInfoEs.map((item, index) => {
                      return(<TableRow role="checkbox" key={index} >
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.definicao}</TableCell>
                        <TableCell>{item.relacao}</TableCell>
                      </TableRow>)
                    })

                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Typography style={{marginBottom: '10px'}}>
              {lb["home.fase1Title1"]}
              <br/>
              {lb["home.fase1Title2"]}
            </Typography>
            {homeState.dataNasa.nasa.fase2 !== null && homeState.dataNasa.nasa.fase2 !== undefined  && homeState.dataNasa.nasa.fase2.map((item, index) => {
              var selected1 = false, selected2 = false;                     
              try{
                values.nasaFase2.map(nasa => {
                  if(nasa.id === item.id && nasa.resposta === item.opcao1.id){
                    selected1=true
                    selected2=false
                  }else if(nasa.id === item.id && nasa.resposta === item.opcao2.id){
                    selected1=false
                    selected2=true
                  }
                  return nasa
                })
              }catch(err){
                console.log(err);
              }
              return(
                <Grid item>
                  <Typography style={{padding: '10px', backgroundColor: '#d6d6d6', borderRadius: 5}}>{lb["home.title5"]}</Typography>
                  <Grid item style={{border: '1px solid #d6d6d6', borderRadius: 5, padding: '10px', margin: '10px 0px'}}>
                    <Tooltip
                      title={item.opcao1.descricao === lb["nasa.opcao0"]? 
                        lang === 'pt_BR'? tooltipMentalBr : (lang === 'en' ? tooltipMentalEn : tooltipMentalEs) :
                      item.opcao1.descricao === lb["nasa.opcao1"]? 
                        lang === 'pt_BR'? tooltipFisicaBr : (lang === 'en' ? tooltipFisicaEn : tooltipFisicaEs) :
                      item.opcao1.descricao === lb["nasa.opcao2"]?
                        lang === 'pt_BR'? tooltipTemporalBr : (lang === 'en' ? tooltipTemporalEn : tooltipTemporalEs)  :
                      item.opcao1.descricao === lb["nasa.opcao3"]?
                        lang === 'pt_BR'? tooltipDesempenhoBr : (lang === 'en' ? tooltipDesempenhoEn : tooltipDesempenhoEs) :
                      item.opcao1.descricao === lb["nasa.opcao4"]?
                        lang === 'pt_BR'? tooltipEsforcoBr : (lang === 'en' ? tooltipEsforcoEn : tooltipEsforcoEs)  :
                        lang === 'pt_BR'? tooltipFrustacaoBr : (lang === 'en' ? tooltipFrustacaoEn : tooltipFrustacaoEs)
                      }
                    >
                      <Button 
                        variant="outlined" className={classes.homeButton} 
                        style={selected1?{backgroundColor: color.palette.secondary.main, color: '#fff'}:null} 
                        onClick={() => this.setNasaFase2(item, true)}
                      >
                        {item.opcao1.descricao}  
                      </Button>
                    </Tooltip>
                    <Tooltip 
                      title={item.opcao2.descricao === lb["nasa.opcao0"]? 
                        lang === 'pt_BR'? tooltipMentalBr : lang === 'en'? tooltipMentalEn : tooltipMentalEs :
                      item.opcao2.descricao === lb["nasa.opcao1"]? 
                        lang === 'pt_BR'? tooltipFisicaBr : lang === 'en'? tooltipFisicaEs : tooltipFisicaEs  :
                      item.opcao2.descricao === lb["nasa.opcao2"]?
                        lang === 'pt_BR'? tooltipTemporalBr : lang === 'en'? tooltipTemporalEn : tooltipTemporalEs :
                      item.opcao2.descricao === lb["nasa.opcao3"]?
                        lang === 'pt_BR'? tooltipDesempenhoBr : lang === 'en'? tooltipDesempenhoEn : tooltipDesempenhoEs :
                      item.opcao2.descricao === lb["nasa.opcao4"]?
                        lang === 'pt_BR'? tooltipEsforcoBr : lang === 'en'? tooltipEsforcoEn : tooltipEsforcoEs :
                        lang === 'pt_BR'? tooltipFrustacaoBr : lang === 'en'? tooltipFrustacaoEn : tooltipFrustacaoEs
                      }
                    >
                      <Button 
                        variant="outlined" 
                        className={classes.homeButton} 
                        style={selected2?{backgroundColor: color.palette.secondary.main, color: '#fff'}:null} 
                        onClick={() => this.setNasaFase2(item, false)}
                      >
                        {item.opcao2.descricao}
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            })}
            <Typography style={{marginTop: '20px'}}>
              {lb["home.fase2Title1"]}
              <br/>
              {lb["home.fase2Title2"]}
            </Typography>
            {homeState.dataNasa.nasa.fase1 !== null && homeState.dataNasa.nasa.fase1 !== undefined  && homeState.dataNasa.nasa.fase1.map((item, index) => {
              var selected = 0;                     
              try{
                values.nasaFase1.map(nasa => {
                  if(nasa.id === item.id){
                    selected = nasa.resposta;
                  }
                  return nasa
                })
              }catch(err){
                console.log(err);
              }
              return (
                <Grid item style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#d6d6d6", borderRadius: 5, margin: '10px 0px', padding: '10px'}}>
                  <Grid item style={{display: 'flex', flexDirection: 'row'}}>
                    <Typography><b>{item.nasaOpcao.descricao}</b></Typography>
                    <HelpOutlineIcon fontSize='small' style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => this.modalExplicacao(item.nasaOpcao.explicacao)}/>
                  </Grid>
                  <Typography>{item.descricao}</Typography>
                  <Grid item style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 15px'}}>
                    <Slider
                      //value={selected}
                      defaultValue={selected}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={20}
                      onChangeCommitted={(e, value) => this.setNasa(value, item)}
                      marks={lang === 'pt_BR'? item.id === 6 ? markInvertidoBr : markBr : lang === 'en' ? item.id === 6 ? markInvertidoEn : markEn : item.id === 6 ? markInvertidoEs : markEs}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )
      // case 3:
      // return (
      //   <Grid item style={{display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center'}}>
      //     <Grid item style={{padding: '10px 20px', maxWidth: '800px'}}>
      //       <Typography>
      //         {values.nome}, {lb["home.title6"]}
      //         <br/>
      //         {lb["home.title7"]}
      //         <br/>
      //         {lb["home.title88"]}
      //       </Typography>

      //       <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      //         <Zoom
      //           img={imgExample}
      //           zoomScale={2}
      //           width={200}
      //           height={200}
      //         />
      //       </Grid>

      //       <Grid>
      //         {!isMobile && (
      //           <Button onClick={() => this.setState({tirarFoto: !this.state.tirarFoto})} fullWidth color='secondary' variant="contained" style={{margin: '15px 0px'}} disabled={cameraError}>
      //             {tirarFoto? lb["button.foto1"]:lb["button.foto2"]}
      //           </Button>
      //         )}
      //       </Grid>
      //       {
      //         tirarFoto? 
      //         <Grid item style={{margin: '10px 0px'}}>
      //         {preview === null ?
      //           <Camera
      //             onTakePhoto={this.saveData}
      //             idealFacingMode={FACING_MODES.ENVIRONMENT}
      //             idealResolution={{ width: 312, height: 312 }}
      //             imageType={IMAGE_TYPES.JPG}
      //             imageCompression={0.97}
      //             isMaxResolution={false}
      //             isImageMirror={false}
      //             isSilentMode
      //             sizeFactor={1}
      //             onCameraError = { (error) =>  this.setState({tirarFoto: false, cameraError: true}) }
      //           />
      //           :
      //           <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      //             <img
      //               src={preview}
      //               alt="Icone do Paciente"
      //             />
      //             <Button 
      //               style={{border: '1px solid black', width: 320, borderTopColor: "rgba(255,255,255,0)"}}
      //               onClick={() => this.setState({preview: null})}
      //             >
      //               <DeleteForeverIcon />
      //             </Button>
      //           </Grid>
      //         }
      //       </Grid>
      //       :
      //       <Grid style={{height: '200px'}}>
      //         {
      //           previewDrop === null?
      //           <MagicDropzone
      //             style={{backgroundColor: '#d6d6d6', height: '100%', display: 'flex', justifyContent: "center", alignItems: 'center', fontWeight: 'bold'}}
      //             accept="image/jpeg, image/png, .jpg, .jpeg, .png"
      //             type="file"
      //             ref={e => this.dropzone = e}
      //             onDrop={this.onDrop}
      //           >
      //             {lb["dropzone"]}
      //           </MagicDropzone>
      //           :
      //           <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      //             <img
      //               style={{width: '100%'}}
      //               src={previewDrop}
      //               alt="Icone do Paciente"
      //             />
      //             <Button 
      //               style={{border: '1px solid black', width: '100%', borderTopColor: "rgba(255,255,255,0)"}}
      //               onClick={() => this.setState({previewDrop: null})}
      //             >
      //               <DeleteForeverIcon />
      //             </Button>
      //           </Grid>
      //         }
      //       </Grid>
      //       }
      //     </Grid>
      //   </Grid>
      // )
    }
  }

  modalExplicacao = (explicacao) => {
    this.setState({modalExplicacao: true, explicacao})
  }

  modalCorlett = () => {
    this.setState({modalCorlett: true})
  }

  onDrop = async (accepted) => {
    const {notify} = this.props
    try {
      const file = accepted[0];
      // // console.log('file', file)
      const $ = this;
      const reader = new FileReader();

      const extention = file.type.replace('image/', '.');
      
      reader.onloadend = function () {
        $.setState({ previewDrop: reader.result });
        $.setState({ extention: extention });
      };
  
      if (file) {
        reader.readAsDataURL(file);
      }
    }catch(err){
      console.log(err)
      notify(`Error, ${err}`, { variant: 'error' });
    }
  };

  saveData = (preview) => {
    this.setState({ preview });
  }

  // uploadImage = async() => {
  //   // const {notify} = this.props
  //   const {preview, data, previewDrop, tirarFoto, reformulacao, reformulacaoData, extention} = this.state;
  //   const previewDropStr = String(previewDrop)

  //   // // console.log('previewDropStr', previewDropStr)
  //   // // console.log('preview',preview)
  //   let cut = previewDropStr.indexOf('base64,') + 7;
  //   let cut2 = String(preview).indexOf('base64,') + 7
  //   this.setState({loading: true})
  //   try{
  //     let foto = '';
  //     if(tirarFoto){
  //       foto = preview.substring(cut2)
  //     }else{
  //       foto = previewDropStr.substring(cut)
  //     }
  //     const base64 = foto;

  //     let filter = {};
  //     const dashboard = JSON.parse(localStorage.getItem('@alicrim:dashboard'));
  //     const codigoCliente = dashboard === null ? null : dashboard.clienteSelecionado === null ? null : dashboard.clienteSelecionado.codigo;
  //     if(reformulacao){
  //       filter = {
  //           key: reformulacaoData.id,
  //           extention: extention, 
  //           imageBase64: base64,
  //           clientCode: codigoCliente
  //       }
  //       await apiS3Alicrim.post(`/homeoffice/insert`, filter)
  //     }else{
  //       filter = {
  //           key: data.id,
  //           extention: extention, 
  //           imageBase64: base64,
  //           clientCode: codigoCliente
  //       }
  //       await apiS3Alicrim.post(`/homeoffice/insert`, filter)
  //     }

  //     // if(reformulacao){
  //     //   await apiS3.post(`/imagem/b64/alicrim/${reformulacaoData.id}`, base64)
  //     // }else{
  //     //   await apiS3.post(`/imagem/b64/alicrim/${data.id}`, base64)
  //     // }
  //     return true;
  //   }catch(err){
  //     console.log(err)
  //     return false;
  //   }
  // };

  setNasa = (value, nasa) => {
    const {setFieldValue, values} = this.props;
    var posicaoExist = null;
    var exist = false;
    values.nasaFase1.map((item,index) => {
      if(item.id === nasa.id){
        exist = true;
        posicaoExist = index;
      }
      return item
    });
    const tamanho = values.nasaFase1.length;
    if(exist){
      setFieldValue(`nasaFase1[${posicaoExist}]`, {
        descricao: nasa.descricao,
        id: nasa.id,
        nasaOpcao: {
          descricao: nasa.nasaOpcao.descricao,
          id: nasa.nasaOpcao.id
        },
        resposta: value
      });
    }else{
      setFieldValue(`nasaFase1[${tamanho}]`, {
        descricao: nasa.descricao,
        id: nasa.id,
        nasaOpcao: {
          descricao: nasa.nasaOpcao.descricao,
          id: nasa.nasaOpcao.id
        },
        resposta: value
      });
    }
  }

  setNasaFase2 = (nasa, opcao) => {
    const {setFieldValue, values} = this.props;
    var posicaoExist = null;
    var exist = false;
    values.nasaFase2.map((item,index) => {
      if(item.id === nasa.id){
        exist = true;
        posicaoExist = index;
      }
      return item;
    });
    const tamanho = values.nasaFase2.length;
    const nasaResposta = opcao? nasa.opcao1.id : nasa.opcao2.id;
    if(exist){
      setFieldValue(`nasaFase2[${posicaoExist}]`, {
        id: nasa.id,
        opcao1: {
          descricao: nasa.opcao1.descricao,
          id: nasa.opcao1.id
        },
        opcao2: {
          descricao: nasa.opcao2.descricao,
          id: nasa.opcao2.id
        },
        resposta: nasaResposta
      });
    }else{
      setFieldValue(`nasaFase2[${tamanho}]`, {
        id: nasa.id,
        opcao1: {
          descricao: nasa.opcao1.descricao,
          id: nasa.opcao1.id
        },
        opcao2: {
          descricao: nasa.opcao2.descricao,
          id: nasa.opcao2.id
        },
        resposta: nasaResposta
      });
    }
  }

  changeResposta = (per, res, tipo) => {
    const {setFieldValue, values} = this.props;
    const {homeState} = this.state;
    var posicaoExist = null;
    var exist = false;
    // console.log('homeStatechangeResposta', homeState)
    if(tipo){
      homeState.dataNasa.senso.map((item,index) => {
        if(item.regiaoCorpo === per){
          exist = true;
          posicaoExist = index;
        }
        return item
      })
      const tamanho = homeState.dataNasa.senso.length;
      if(exist){
        setFieldValue(`senso[${posicaoExist}]`, {nivelDor: res, regiaoCorpo: per});
      }else{
        setFieldValue(`senso[${tamanho}]`, {nivelDor: res, regiaoCorpo: per});
      }
    }else {
      values.homeOffice.map((item,index) => {
        if(item.pergunta === per){
          exist = true;
          posicaoExist = index;
        }
        return item
      })
      const tamanho = values.homeOffice.length;
      if(exist){
        setFieldValue(`homeOffice[${posicaoExist}]`, {resposta: res, pergunta: per});
      }else{
        setFieldValue(`homeOffice[${tamanho}]`, {resposta: res, pergunta: per});
      }
    }
  }

  scrollToTop() {
    this.refs.cadastro.scrollIntoView();
  }

  /*
    VERIFICAÇÕES
  */
  validateHomeQuestion = () =>{
    const {values, notify} = this.props;
    const {activeStep, lang, homeState} = this.state;
    var homeTamanho = 0;
    homeState.dataNasa.homeOffice.map(item => {
      homeTamanho += item.perguntas.length;
      return item
    });
    if(!skipValidation && homeTamanho > values.homeOffice.length){
      let restante = homeTamanho - values.homeOffice.length;
      lang === 'pt_BR'? notify(`Ainda falta responder ${restante} questão(es)!`, { variant: 'error' }):notify(`Ainda falta responder ${restante} questão(es)!`, { variant: 'error' });
    }else{
      this.setState({activeStep: activeStep+1})
    }
    this.refs.cadastro.scrollIntoView();
  }

  validateSensoQuestion = () =>{
    const {values, notify} = this.props;
    const {activeStep, lang, homeState} = this.state;
    var sensoTamanho = homeState.dataNasa.senso.length;
    if(!skipValidation && sensoTamanho > values.senso.length){
      let restante = sensoTamanho - values.senso.length;
      lang === 'pt_BR'? notify(`Ainda falta responder ${restante} questão(es)!`, { variant: 'error' }):notify(`Ainda falta responder ${restante} questão(es)!`, { variant: 'error' });
    }else{
      this.setState({activeStep: activeStep+1})
    }
    this.refs.cadastro.scrollIntoView();
  }

  validateNasaQuestion = () => {
    const {handleSubmit,values, setFieldValue, notify} = this.props;
    const { lb, homeState} = this.state;
    const fase1Tamamho = homeState.dataNasa.nasa.fase1.length;
    const fase2Tamamho = homeState.dataNasa.nasa.fase2.length;

    if(fase1Tamamho > values.nasaFase1.length){
      const restante = homeState.dataNasa.nasa.fase1.map(item => {
        var exist = false;
        values.nasaFase1.map(nasa => {
          if(nasa.id === item.id){
            exist = true;
          }
          return nasa
        });
        if(!exist){
          return item
        }else{
          return false
        }
      })
      var tamanho = values.nasaFase1.length-1;
      restante.map(nasa => {
        if(nasa){
          tamanho+=1;
          setFieldValue(`nasaFase1[${tamanho}]`, {
            descricao: nasa.descricao,
            id: nasa.id,
            nasaOpcao: {
              descricao: nasa.nasaOpcao.descricao,
              id: nasa.nasaOpcao.id
            },
            resposta: 0
          });
        }
        return nasa;
      })
    }
    if(!skipValidation && fase2Tamamho > values.nasaFase2.length){
      notify(lb["notify.erroQuest"], { variant: 'error' });
    }else{
      handleSubmit();
      // this.setState({activeStep: activeStep+1});
      this.setState({loading: false})
    }
    this.refs.cadastro.scrollIntoView();
  }

  validatePhoto = async () => {
    // const {notify} = this.props;
    const { lb } = this.state;
    try{
      const {handleSubmit, notify, setFieldValue} = this.props;
      const {preview, previewDrop, reformulacaoData, reformulacao, homeState} = this.state;
      if(preview === null && previewDrop === null ){
        notify(lb["notify.avisoFoto"], { variant: 'error' });
      }else{
        const validateImage = await this.uploadImage()
        if(validateImage){
          if(reformulacao){
            setFieldValue('id', reformulacaoData.id)
          }else{
            setFieldValue('id', homeState.dataNasa.id)
          }
          handleSubmit();
        }else{
          notify(lb["notify.erroImg"], { variant: 'error' });
        }
  
      }
      this.setState({loading: false})
    }catch(err){
      console.log(err)
    }   
  }

  render() {
      const {
        activeStep, 
        steps,
        init,
        loading,
        send,
        modalExplicacao,
        explicacao,
        modalCorlett,
        lang,
        lb,
        finishedVideo
      } = this.state;
      const {
        classes,
        match,
      } = this.props;
    return (
      <Grid className={init ? init && send ? classes.containerinit : classes.container : classes.containerinit}>
        {!init?
          <Home style={{ display : (init ? 'none' : 'block')}} next={value => this.nextHome(value)} match={match} lang={lang} handleSetLinguage={(value) => this.handleSetLinguage(value)}/>
          :
          null
        }
       {init && send? 
          <Grid style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: color.palette.success.main, color: "#fff"}}>
            <CheckCircleIcon fontSize="large" />
            <Typography>{lb["home.title8"]}</Typography>
          </Grid>
          :
          // null}
          <Grid item style={{ display : (!init ? 'none' : 'flex'), flexDirection: 'column',  height: '100%'}} >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} style={{ cursor: 'pointer' }}>
                  <StepLabel onClick={() => {
                    if (activeStep >= index && activeStep) {
                      if (activeStep !== 1) {
                        this.setState({ activeStep: index });
                      }
                    }
                  }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid item style={{ flexGrow: 1, width: '100%', display: 'flex'}}>
              <div ref='cadastro'/>
              {this.getContent(activeStep)}
            </Grid>       
            <Grid item style={{padding: '10px', textAlign:'right'}}>
              <Button 
                className={classes.button} 
                variant="contained" 
                color="secondary" 
                disabled={loading || activeStep === 0}
                onClick={() => this.setState({activeStep: activeStep-1}, () => this.scrollToTop())} 
              >
                {lb["button.anterior"]}
              </Button>
              <Button className={classes.button} variant="contained" color="secondary" disabled={loading || !finishedVideo} onClick={() => {
                switch(activeStep){
                  // case 1: 
                  //   return this.validateHomeQuestion();
                  case 1: 
                    return this.validateSensoQuestion();
                  case 2:
                    return this.validateNasaQuestion();
                  // case 3: 
                  //   return this.validatePhoto(); 
                  default:
                    this.setState({activeStep: activeStep+1})
                    this.refs.cadastro.scrollIntoView();
                }
              }}>
                {loading? 
                  <CircularProgress size={25}/>
                  :
                  activeStep===2? lb["button.finalizar"] : lb["button.continuar"]
                }
              </Button>
            </Grid>
            <Modal
              open={modalExplicacao}
              onClose={() => this.setState({modalExplicacao: false})}
              style={{display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none'}}
            >
              <AppBar className={classes.appBar} style={{outline: 'none'}}>
                <ToolbarDialog>
                  <Typography variant="h6" className={classes.titleDialog}>
                    {lb["button.ajuda"]}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => this.setState({modalExplicacao: false})} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </ToolbarDialog>
                <Grid style={{backgroundColor: '#fff', width: '100%', height: '100%', padding: '20px', borderRadius: 5, color: '#000'}}>
                  <Typography style={{marginBottom: '15px'}}><b>{lb["modal.descricao"]}</b></Typography>
                  <Typography>{explicacao}</Typography>
                </Grid> 
              </AppBar>
            </Modal>
            <Modal
              open={modalCorlett}
              onClose={() => this.setState({modalCorlett: false})}
              style={{display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none'}}
            >
              <AppBar className={classes.appBar} style={{outline: 'none'}}>
                <ToolbarDialog>
                  <Typography variant="h6" className={classes.titleDialog}>
                    {lb["button.ajuda"]}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => this.setState({modalCorlett: false})} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </ToolbarDialog>
                <Grid style={{backgroundColor: '#fff', padding: '20px', borderRadius: 5, color: '#000', display: 'flex', justifyContent: 'center', outline: 'none'}}>
                  {/* eslint-disable-next-line*/}
                  <img src={lang === 'pt_BR'?corlett: lang === 'en' ? corlettEn : corlett}/>
                </Grid> 
              </AppBar>
            </Modal>
          </Grid>            
          } 
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({  
  notify: (message, options) => dispatch(NotificationActions.notify(message, options)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Material, { withTheme: true }),
  withFormik({
    displayName: 'Questionario',
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      cargo: null,
      email: "",
      homeOffice: [],
      id: null,
      _id: "",
      matricula: "",
      nasaFase1: [],
      nasaFase2: [],
      nome: "",
      senso: [],
      unidade: 0,
      setor:null,
      localTrabalho: null,
      apiKey: "",
      isReturn:false,
      ghe:null,
      turno:null,
      codigoUsuario:null,
      registryDate: null
    }),
    handleSubmit: async (values, { props, setSubmitting, resetForm, setStatus }) => {
      // // console.log(values)
      // // console.log(window.location);
      let path = window.location.pathname;
      // // console.log('pathcontains', path.includes('ho'))
      if(path.includes('ho')) {
        values.returns = true;
      } else {
        values.returns = false;
      }
      const form = {
        ...values
      }
      try {
       

        // console.log('form', form)
        await api.post('/quiz/saveInterno', {...form});
        await setStatus({ updateLista: true });        
        resetForm();
      } catch (err) {
        props.notify(`Houve um problema ao salvar, ${err}`, { variant: 'error' });
        console.log(err);
      }
    },
  }),
)(Questionario);